/**
 * @copyright 2020 @ DigiNet
 * @author NHATLONG
 * @create 10/09/2020
 * @Example
 */

export const types = {
    W06F0004_GET_LIST_OBJECTS: "W06F0004_GET_LIST_OBJECTS",
    W06F0004_GET_LIST_INVENTORIES: "W06F0004_GET_LIST_INVENTORIES",
    W06F0004_GET_MAPPING: "W06F0004_GET_MAPPING"
};

export function getListObjects(params, cb) {
    return {
        type: types.W06F0004_GET_LIST_OBJECTS,
        params,
        cb
    }
}
export function getInventories(params, cb) {
    return {
        type: types.W06F0004_GET_LIST_INVENTORIES,
        params,
        cb
    }
}
export function getMapping(params, cb) {
    return {
        type: types.W06F0004_GET_MAPPING,
        params,
        cb
    }
}