/**
 * @copyright 2020 @ DigiNet
 * @author NHATLONG
 * @create 10/09/2020
 * @Example
 */

import { delay } from "redux-saga";
import { cancel, take, takeLatest, takeEvery } from "redux-saga/effects";
import Api from '../../../services/api';
import { types } from "./W06F0004_actions";

export default function W06F0004Sagas() {
    return [
        watchGetListObjects(),
        watchGetInventories(),
        watchGetMapping()
    ];
}
export function* getListObjects(data) {
    try {
        yield delay(300);
        const res = yield Api.put("/w91f0001/load-objects", data.params);
        if (res && res.data) {
            data.cb && data.cb(null, res.data);
            return;
        } else {
            data.cb && data.cb(res, null);
        }
    } catch (e) { }
}

export function* watchGetListObjects() {
    while (true) {
        const watcher = yield takeEvery(
            types.W06F0004_GET_LIST_OBJECTS,
            getListObjects
        );
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}
export function* getInventories(data) {
    try {
        yield delay(300);
        const res = yield Api.put("/w05f0000/get-inventories", data.params);
        if (res && res.data) {
            data.cb && data.cb(null, res.data);
            return;
        } else {
            data.cb && data.cb(res, null);
        }
    } catch (e) { }
}

export function* watchGetInventories() {
    while (true) {
        const watcher = yield takeLatest(
            types.W06F0004_GET_LIST_INVENTORIES,
            getInventories
        );
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}
export function* getMapping(data) {
    try {
        yield delay(300);
        const res = yield Api.put("/w06f0004/get-mapping", data.params);
        if (res && res.data) {
            data.cb && data.cb(null, res.data);
            return;
        } else {
            data.cb && data.cb(res, null);
        }
    } catch (e) { }
}

export function* watchGetMapping() {
    while (true) {
        const watcher = yield takeLatest(
            types.W06F0004_GET_MAPPING,
            getMapping
        );
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}