/**
 * @copyright 2020 @ DigiNet
 * @author ANHTAI
 * @create 06/08/2020
 * @Example
 */
export const types = {
    W91F0001_GET_TIMEKEEPINGLIST: "W91F0001_GET_TIMEKEEPINGLIST",
    W91F0001_GET_TIMEKEEPINGLIST_SUCCESS: "W91F0001_GET_TIMEKEEPINGLIST_SUCCESS"
};

export function getTimeKeepingList(params, cb) {
    return {
        type: types.W91F0001_GET_TIMEKEEPINGLIST,
        params,
        cb
    }
}