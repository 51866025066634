/**
 * @copyright 2020 @ DigiNet
 * @author ANHTAI
 * @create 18/11/2020
 * @Example
 */

export const types = {
    W06F0005_GET_LIST_OBJECTS: "W06F0005_GET_LIST_OBJECTS",
    W06F0005_GET_LIST_INVENTORIES: "W06F0005_GET_LIST_INVENTORIES",
    W06F0005_GET_MAPPING: "W06F0005_GET_MAPPING",
    W06F0005_GET_FLEX_CAPTION: "W06F0005_GET_FLEX_CAPTION",
    W06F0005_GET_FLEX_CAPTION_SUCCESS: "W06F0005_GET_FLEX_CAPTION_SUCCESS",
    W06F0005_GET_CHOSEN_MAPPING: "W06F0005_GET_CHOSEN_MAPPING",
    W06F0005_GET_CHOSEN_MAPPING_SUCCESS: "W06F0005_GET_CHOSEN_MAPPING_SUCCESS",
};

export function getListObjects(params, cb) {
    return {
        type: types.W06F0005_GET_LIST_OBJECTS,
        params,
        cb
    }
}
export function getInventories(params, cb) {
    return {
        type: types.W06F0005_GET_LIST_INVENTORIES,
        params,
        cb
    }
}
export function getMapping(params, cb) {
    return {
        type: types.W06F0005_GET_MAPPING,
        params,
        cb
    }
}

export function getFlexCaption(params, cb) {
    return {
        type: types.W06F0005_GET_FLEX_CAPTION,
        params,
        cb
    }
}

export function getChosenMapping(params, cb) {
    return {
        type: types.W06F0005_GET_CHOSEN_MAPPING,
        params,
        cb
    }
}

