export const types = {
    W06F0007_GET_DECIMALS: "W06F0007_GET_DECIMALS",
    W06F0007_GET_DECIMALS_SUCCESS: "W06F0007_GET_DECIMALS_SUCCESS",
    W06F0007_GET_CAPTION: "W06F0007_GET_CAPTION",
    W06F0007_GET_CB_DELIVERY: "W06F0007_GET_CB_DELIVERY",
    W06F0007_GET_CB_DELIVERY_SUCCESS: "W06F0007_GET_CB_DELIVERY_SUCCESS",
    W06F0007_GET_CB_DELIVERY_STATUS: "W06F0007_GET_CB_DELIVERY_STATUS",
    W06F0007_GET_CB_DELIVERY_STATUS_SUCCESS: "W06F0007_GET_CB_DELIVERY_STATUS_SUCCESS",
    W06F0007_GET_CB_UNITPACK: "W06F0007_GET_CB_UNITPACK",
    W06F0007_GET_CB_WAREHOUSE_PACKING: "W06F0007_GET_CB_WAREHOUSE_PACKING",
    W06F0007_GET_CB_MARKING: "W06F0007_GET_CB_MARKING",
    W06F0007_GET_CB_MARKING_SUCCESS: "W06F0007_GET_CB_MARKING_SUCCESS",
    W06F0007_GET_FORM_EDIT: "W06F0007_GET_FORM_EDIT",
    W06F0007_GET_FORM_EDIT_SUCCESS: "W06F0007_GET_FORM_EDIT_SUCCESS",
    W06F0007_GET_LIST_OBJECTS: "W06F0007_GET_LIST_OBJECTS",
    W06F0007_GET_LIST_INVENTORIES: "W06F0007_GET_LIST_INVENTORIES",
    W06F0007_SAVE_APPROVAL: "W06F0007_SAVE_APPROVAL",
    W06F0007_ON_REFUSE: "W06F0007_ON_REFUSE",
    W06F0007_ON_REFUSE_SUCCESS: "W06F0007_ON_REFUSE_SUCCESS",
}

export function getDecimals(cb) {
    return {
        type: types.W06F0007_GET_DECIMALS,
        cb
    }
}

export function getCation(params, cb) {
    return {
        type: types.W06F0007_GET_CAPTION,
        params,
        cb
    }
}

export function getCbDelivery(params, cb) {
    return {
        type: types.W06F0007_GET_CB_DELIVERY,
        params,
        cb
    }
}

export function getcbUnitPack(params, cb) {
    return {
        type: types.W06F0007_GET_CB_UNITPACK,
        params,
        cb
    }
}

export function getcbWarehousePacking(params, cb) {
    return {
        type: types.W06F0007_GET_CB_WAREHOUSE_PACKING,
        params,
        cb
    }
}

export function getcbDeliveryStatus(params, cb) {
    return {
        type: types.W06F0007_GET_CB_DELIVERY_STATUS,
        params,
        cb
    }
}

export function getCbMarking(params, cb) {
    return {
        type: types.W06F0007_GET_CB_MARKING,
        params,
        cb
    }
}

export function getFormEdit(params, cb) {
    return {
        type: types.W06F0007_GET_FORM_EDIT,
        params,
        cb
    }
}

export function getListObjects(params, cb) {
    return {
        type: types.W06F0007_GET_LIST_OBJECTS,
        params,
        cb
    }
}

export function getInventories(params, cb) {
    return {
        type: types.W06F0007_GET_LIST_INVENTORIES,
        params,
        cb
    }
}

export function saveApproval(params, cb) {
    return {
        type: types.W06F0007_SAVE_APPROVAL,
        params,
        cb
    }
}

export function onRefuse(params, cb) {
    return {
        type: types.W06F0007_ON_REFUSE,
        params,
        cb
    }
}
