/**
 * @copyright 2020 @ DigiNet
 * @author ANHTAI
 * @create 06/08/2020
 * @Example
 */

import { delay } from "redux-saga";
import { cancel, put, take, takeLatest } from "redux-saga/effects";
import Api from '../../../services/api';
import { types } from "./W91F0001_actions";

export default function W91F0001Sagas() {
    return [
        watchGetTimeKeepingList()
    ];
}

export function* getTimeKeepingList(data) {
    try {
        yield delay(300);
        const res = yield Api.put("/w91f0001/load-objects", data.params);
        if (res && res.data) {
            yield put({
                type: types.W91F0001_GET_TIMEKEEPINGLIST_SUCCESS,
                data: res.data,
                param: data.params
            });
            data.cb && data.cb(null, res.data);
            return;
        } else {
            data.cb && data.cb(res, null);
        }
    } catch (e) { }
}

export function* watchGetTimeKeepingList() {
    while (true) {
        const watcher = yield takeLatest(
            types.W91F0001_GET_TIMEKEEPINGLIST,
            getTimeKeepingList
        );
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

