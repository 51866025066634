/**
 * @copyright 2020 @ DigiNet
 * @author TAIAU
 * @create 07/13/2020
 * @Example
 */

import { delay } from "redux-saga";
import { cancel, put, take, takeLatest } from "redux-saga/effects";
import Api from '../../../services/api';
import { types } from "./W06F0003_actions";

export default function W06F0003Sagas() {
    return [
        watchGetDetailSupplement(),
        watchGetMasterSupplement(),
        watchLoadFormView(),
        watchGetDecimalQuanlity(),
        watchGetCboEmployee(),
        watchGetCboBaseCurrency(),
        watchGetMappingBySaleContract(),
    ];
}

//get detail supplement
export function* getDetailSupplement(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w06f0000/get-detail-supplement', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get detail supplement error');
    }
}

export function* watchGetDetailSupplement() {
    while (true) {
        const watcher = yield takeLatest(types.W06F0003_GET_DETAIL_SUPPLEMENT, getDetailSupplement);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//get master supplement
export function* getMasterSupplement(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w05f0000/get-master-supplement', data.params);
        if (response && response.data) {
            yield put({ type: types.W06F0003_GET_MASTER_SUPPLEMENT_SUCCESS, data: response.data, params: data.params });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get master supplement error');
    }
}

export function* watchGetMasterSupplement() {
    while (true) {
        const watcher = yield takeLatest(types.W06F0003_GET_MASTER_SUPPLEMENT, getMasterSupplement);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}
//get load form
export function* loadFormView(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w06f0000/load-form-view', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get load form error');
    }
}

export function* watchLoadFormView() {
    while (true) {
        const watcher = yield takeLatest(types.W06F0003_LOAD_FORM_VIEW, loadFormView);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//save data
export function* saveDataContract(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w06f0000/insert', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('save error');
    }
}

export function* watchSaveDataContract() {
    while (true) {
        const watcher = yield takeLatest(types.W06F0003_SAVE_DATA_CONTRACT, saveDataContract);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}


//get decimal quanlity
export function* getDecimalQuanlity(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w06f0000/get-decimal-quantity', data.params);
        if (response && response.data) {
            yield put({ type: types.W06F0003_GET_DECIMAL_QUANTITY_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get decimal quanlity error');
    }
}

export function* watchGetDecimalQuanlity() {
    while (true) {
        const watcher = yield takeLatest(types.W06F0003_GET_DECIMAL_QUANTITY, getDecimalQuanlity);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//get cbo employee
export function* getCboEmployee(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w05f0000/get-combo-employee', data.params);
        if (response && response.data) {
            yield put({ type: types.W06F0003_GET_CBO_EMPLOYEE_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get cbo employee error');
    }
}

export function* watchGetCboEmployee() {
    while (true) {
        const watcher = yield takeLatest(types.W06F0003_GET_CBO_EMPLOYEE, getCboEmployee);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//get cbo base currency
export function* getCboBaseCurrency(data) {
    try {
        yield delay(300);
        const response = yield Api.get('/w06f0000/get-base-currency');
        if (response && response.data) {
            yield put({ type: types.W06F0003_GET_BASE_CURRENCY_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get cbo base currency error');
    }
}

export function* watchGetCboBaseCurrency() {
    while (true) {
        const watcher = yield takeLatest(types.W06F0003_GET_BASE_CURRENCY, getCboBaseCurrency);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//Get mapping by sale contract
export function* getMappingBySaleContract(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w05f0006/get-mapping-by-sale-contract', data.params);
        if (response && response.data) {
            yield put({ type: types.W06F0003_GET_MAPPING_BY_SALE_CONTRACTS_SUCCESS, data: response.data, params: data.params });
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('get mapping by sale contract error')
    }
}

export function* watchGetMappingBySaleContract() {
    while (true) {
        const watcher = yield takeLatest(types.W06F0003_GET_MAPPING_BY_SALE_CONTRACTS, getMappingBySaleContract);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }

}