/**
 * @copyright 2020 @ DigiNet
 * @author ANHTAI
 * @create 03/11/2020
 */

import _ from "lodash";
import { delay } from "redux-saga";
import { cancel, put, take, takeLatest } from "redux-saga/effects";
import Api from '../../../services/api';
import { types } from "./W94F1000_actions";

export default function W94F1000Sagas() {
    return [
        watchGetGroupList(),
        watchGetControlMaster(),
        watchGetControlsDetailReport(),
    ];
}

export function* getGroupList(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w94f1000/get-reports', data.params);
        if (response && response.data) {
            if (_.isArray(response.data) && !_.isEmpty(response.data)) yield put({ type: types.W94F1000_GET_GROUP_LIST_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get report list or view detail error', e);
    }
}

export function* watchGetGroupList() {
    while (true) {
        const watcher = yield takeLatest(types.W94F1000_GET_GROUP_LIST, getGroupList);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* getControlMaster(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w94f1002/get-controls', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get report detail error');
    }
}

export function* watchGetControlMaster() {
    while (true) {
        const watcher = yield takeLatest(types.W94F1002_GET_CONTROLS_MASTER, getControlMaster);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//get struct report..
export function* getControlsDetailReport(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w94f1002/get-controls-detail', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get report controls detail error');
    }
}

export function* watchGetControlsDetailReport() {
    while (true) {
        const watcher = yield takeLatest(types.W94F1002_GET_CONTROLS_DETAIL_REPORT, getControlsDetailReport);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}