/**
 * @copyright 2020 @ DigiNet
 * @author TAIAU
 * @create 07/13/2020
 * @Example
 */

import { delay } from "redux-saga";
import { cancel, put, take, takeLatest } from "redux-saga/effects";
import Api from '../../../services/api';
import { types } from "./W06F0002_actions";

export default function W06F0002Sagas() {
    return [
        watchGetCboContractType(),
        watchGetCboCurrency(),
        watchGetCboEmployees(),
        watchGetCboStatus(),
        watchGetInventories(),
        watchGetCboPackingMethod(),
        watchGetBaseCurrency(),
        watchGetDecimalQuantity(),
        watchGetDetailSupplement(),
        watchGetMasterSupplement(),
        watchGetCboPackingType(),
        watchGetContractNo(),
        watchGetFormData(),
        watchSaveHistoryData(),
        watchSaveEdit(),
        watchSaveAddNew(),
        watchGetPaymentMethod(),
        watchGetDataFormView(),
        watchOnSaveDataUnit(),
        watchGetExceedContract(),
        watchGetCboBankAccount()
    ];
}

//get cbo contract type
export function* getCboContractType(data) {
    try {
        yield delay(300);
        const response = yield Api.get('/w05f0000/get-combo-contractType');
        if (response && response.data) {
            yield put({ type: types.W06F0002_GET_CBO_CONTRACT_TYPE_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get cbo contract type error');
    }

}

export function* watchGetCboContractType() {
    while (true) {
        const watcher = yield takeLatest(types.W06F0002_GET_CBO_CONTRACT_TYPE, getCboContractType);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//get cbo currency
export function* getCboCurrency(data) {
    try {
        yield delay(300);
        const response = yield Api.get('/w05f0000/get-combo-currency');
        if (response && response.data) {
            yield put({ type: types.W06F0002_GET_CBO_CURRENCY_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get cbo currency error');
    }
}

export function* watchGetCboCurrency() {
    while (true) {
        const watcher = yield takeLatest(types.W06F0002_GET_CBO_CURRENCY, getCboCurrency);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//get cbo employees
export function* getCboEmployees(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w05f0000/get-combo-employee', data.params);
        if (response && response.data) {
            yield put({ type: types.W06F0002_GET_CBO_EMPLOYEES_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get cbo employees error');
    }
}

export function* watchGetCboEmployees() {
    while (true) {
        const watcher = yield takeLatest(types.W06F0002_GET_CBO_EMPLOYEES, getCboEmployees);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//get cbo status
export function* getCboStatus(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w06f0000/get-combo-status', data.params);
        if (response && response.data) {
            yield put({ type: types.W06F0002_GET_CBO_STATUS_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get cbo status error');
    }
}

export function* watchGetCboStatus() {
    while (true) {
        const watcher = yield takeLatest(types.W06F0002_GET_CBO_STATUS, getCboStatus);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//get inventories
export function* getInventories(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w05f0000/get-inventories', data.params);
        if (response && response.data) {
            yield put({ type: types.W06F0002_GET_INVENTORIES_SUCCESS, data: response.data, skip: data.params.skip });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get cbo inventories error');
    }
}

export function* watchGetInventories() {
    while (true) {
        const watcher = yield takeLatest(types.W06F0002_GET_INVENTORIES, getInventories);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//get cbo packing method
export function* getCboPackingMethod(data) {
    try {
        yield delay(300);
        const response = yield Api.get('/w05f0000/get-combo-packing-method');
        if (response && response.data) {
            yield put({ type: types.W06F0002_GET_CBO_PACKING_METHOD_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get cbo packing method error');
    }
}

export function* watchGetCboPackingMethod() {
    while (true) {
        const watcher = yield takeLatest(types.W06F0002_GET_CBO_PACKING_METHOD, getCboPackingMethod);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//get cbo base currency
export function* getBaseCurrency(data) {
    try {
        yield delay(300);
        const response = yield Api.get('/w06f0000/get-base-currency');
        if (response && response.data) {
            yield put({ type: types.W06F0002_GET_BASE_CURRENCY_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get cbo base currency error');
    }
}

export function* watchGetBaseCurrency() {
    while (true) {
        const watcher = yield takeLatest(types.W06F0002_GET_BASE_CURRENCY, getBaseCurrency);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//get decimal quantity
export function* getDecimalQuantity(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w06f0000/get-decimal-quantity', data.params);
        if (response && response.data) {
            yield put({ type: types.W06F0002_GET_DECIMAL_QUANTITY_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get decimal quantity error');
    }
}

export function* watchGetDecimalQuantity() {
    while (true) {
        const watcher = yield takeLatest(types.W06F0002_GET_DECIMAL_QUANTITY, getDecimalQuantity);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//get detail supplement
export function* getDetailSupplement(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w06f0000/get-detail-supplement', data.params);
        if (response && response.data) {
            yield put({ type: types.W06F0002_GET_DETAIL_SUPPLEMENT_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get detail supplement error');
    }
}

export function* watchGetDetailSupplement() {
    while (true) {
        const watcher = yield takeLatest(types.W06F0002_GET_DETAIL_SUPPLEMENT, getDetailSupplement);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//get master supplement
export function* getMasterSupplement(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w05f0000/get-master-supplement', data.params);
        if (response && response.data) {
            yield put({ type: types.W06F0002_GET_MASTER_SUPPLEMENT_SUCCESS, data: response.data, params: data.params });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get master supplement error');
    }
}

export function* watchGetMasterSupplement() {
    while (true) {
        const watcher = yield takeLatest(types.W06F0002_GET_MASTER_SUPPLEMENT, getMasterSupplement);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//get cbo packing type
export function* getCboPackingType(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w05f0000/get-combo-packing-type', data.params);
        if (response && response.data) {
            yield put({ type: types.W06F0002_GET_CBO_PACKING_TYPE_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get cbo packing type error');
    }
}

export function* watchGetCboPackingType() {
    while (true) {
        const watcher = yield takeLatest(types.W06F0002_GET_CBO_PACKING_TYPE, getCboPackingType);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//get contract no
export function* getContractNo(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w05f0000/get-contract-no', data.params);
        if (response && response.data) {
            yield put({ type: types.W06F0002_GET_CONTRACT_NO_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get contract no error');
    }
}

export function* watchGetContractNo() {
    while (true) {
        const watcher = yield takeLatest(types.W06F0002_GET_CONTRACT_NO, getContractNo);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//get grid inventory
export function* getFormData(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w06f0000/load-form-edit', data.params);
        if (response && response.data) {
            yield put({ type: types.W06F0002_LOAD_FORM_DATA_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get grid inventory error');
    }
}

export function* watchGetFormData() {
    while (true) {
        const watcher = yield takeLatest(types.W06F0002_LOAD_FORM_DATA, getFormData);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//Save history
export function* saveHistoryData(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/history/add', data.params);
        if (response && response.data) {
            yield put({ type: types.W06F0002_SAVE_DATA_HISTORY_SUCCESS, data: response.data && response.data.rows });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('save history error');
    }
}

export function* watchSaveHistoryData() {
    while (true) {
        const watcher = yield takeLatest(types.W06F0002_SAVE_DATA_HISTORY, saveHistoryData);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//Save Edit
export function* saveEdit(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w06f0000/edit', data.params);
        if (response && response.data) {
            yield put({ type: types.W06F0002_SAVE_EDIT_SUCCESS, data: response.data && response.data.rows });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('save edit error');
    }
}

export function* watchSaveEdit() {
    while (true) {
        const watcher = yield takeLatest(types.W06F0002_SAVE_EDIT, saveEdit);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//Save Add New
export function* saveAddNew(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w06f0000/insert', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('save add new error');
    }
}

export function* watchSaveAddNew() {
    while (true) {
        const watcher = yield takeLatest(types.W06F0002_SAVE_ADD_NEW, saveAddNew);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//get payment method
export function* getPaymentMethod(data) {
    try {
        yield delay(300);
        const response = yield Api.get('/w05f0000/get-combo-payment-method', data.params);
        if (response && response.data) {
            yield put({ type: types.W06F0002_GET_CBO_PAYMENT_METHOD_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get payment method error');
    }
}

export function* watchGetPaymentMethod() {
    while (true) {
        const watcher = yield takeLatest(types.W06F0002_GET_CBO_PAYMENT_METHOD, getPaymentMethod);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}


//Get Data View
export function* getDataFormView(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w06f0000/load-form-view', data.params);
        if (response && response.data) {
            yield put({ type: types.W06F0002_GET_DATA_FORM_VIEW_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('load form view error');
    }
}

export function* watchGetDataFormView() {
    while (true) {
        const watcher = yield takeLatest(types.W06F0002_GET_DATA_FORM_VIEW, getDataFormView);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//Save combo UnitData local only
export function* onSaveDataUnit(data) {
    yield put({ type: types.W06F0002_SAVE_CBO_UNIT_SUCCESS, data: data.dataCboUnit });
}

export function* watchOnSaveDataUnit() {
    while (true) {
        const watcher = yield takeLatest(types.W06F0002_SAVE_CBO_UNIT, onSaveDataUnit);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

// Get getExceedContract
export function* getExceedContract(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w06f0002/get-default', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('get getExceedContract error');
    }
}

export function* watchGetExceedContract() {
    while (true) {
        const watcher = yield takeLatest(types.W06F0002_GET_EXCEEDCONTRACT, getExceedContract);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* getCboBankAccount(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w05f0002/get-bank-account', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
            yield put({ type: types.W06F0002_GET_CBO_BANK_ACCOUNT_SUCCESS, data: response.data });
        }
        else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('getCboBankAccount error');
    }
}

export function* watchGetCboBankAccount() {
    while (true) {
        const watcher = yield takeLatest(types.W06F0002_GET_CBO_BANK_ACCOUNT, getCboBankAccount);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}
