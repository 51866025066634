/**
 * @copyright 2020 @ DigiNet
 * @author ANHTAI
 * @create 03/11/2020
 */

import { types } from "./W94F1000_actions";

const initialState = {
    listChart: null,
    listGroupChart: null
};

export default function (state = initialState, action = {}) {
    if (action.type === types.W94F1000_GET_GROUP_LIST_SUCCESS) {
        return { ...state, listGroupChart: action.data }
    }
    else if (action.type === types.W94F1000_GET_LIST) {
        return { ...state, listChart: action.data }
    }
    return state
}
