/**
 * @copyright 2020 @ DigiNet
 * @author TAIAU
 * @create 07/13/2020
 * @Example
 */

export const types = {
    W06F0003_GET_DETAIL_SUPPLEMENT: "W06F0003_GET_DETAIL_SUPPLEMENT",
    W06F0003_GET_DETAIL_SUPPLEMENT_SUCCESS: "W06F0003_GET_DETAIL_SUPPLEMENT_SUCCESS",
    W06F0003_GET_MASTER_SUPPLEMENT: "W06F0003_GET_MASTER_SUPPLEMENT",
    W06F0003_GET_MASTER_SUPPLEMENT_SUCCESS: "W06F0003_GET_MASTER_SUPPLEMENT_SUCCESS",
    W06F0003_LOAD_FORM_VIEW: "W06F0003_LOAD_FORM_VIEW",
    W06F0003_LOAD_FORM_VIEW_SUCCESS: "W06F0003_LOAD_FORM_VIEW_SUCCESS",
    W06F0003_GET_DECIMAL_QUANTITY: " W06F0003_GET_DECIMAL_QUANTITY",
    W06F0003_GET_DECIMAL_QUANTITY_SUCCESS: "W06F0003_GET_DECIMAL_QUANTITY_SUCCESS",
    W06F0003_GET_CBO_EMPLOYEE: "W06F0003_GET_CBO_EMPLOYEE",
    W06F0003_GET_CBO_EMPLOYEE_SUCCESS: "W06F0003_GET_CBO_EMPLOYEE_SUCCESS",
    W06F0003_GET_BASE_CURRENCY: "W06F0003_GET_BASE_CURRENCY",
    W06F0003_GET_BASE_CURRENCY_SUCCESS: "W06F0003_GET_BASE_CURRENCY_SUCCESS",
    W06F0003_GET_MAPPING_BY_SALE_CONTRACTS: 'W06F0003_GET_MAPPING_BY_SALE_CONTRACTS',
    W06F0003_GET_MAPPING_BY_SALE_CONTRACTS_SUCCESS: 'W06F0003_GET_MAPPING_BY_SALE_CONTRACTS_SUCCESS',
};

export function getDetailSupplement(params, cb) {
    return {
        type: types.W06F0003_GET_DETAIL_SUPPLEMENT,
        params,
        cb
    }
}

export function getMasterSupplement(params, cb) {
    return {
        type: types.W06F0003_GET_MASTER_SUPPLEMENT,
        params,
        cb
    }
}

export function loadFormView(params, cb) {
    return {
        type: types.W06F0003_LOAD_FORM_VIEW,
        params,
        cb
    }
}

export function saveDataContract(params, cb) {
    return {
        type: types.W06F0003_SAVE_DATA_CONTRACT,
        params,
        cb
    }
}


export function getDecimalQuanlity(params, cb) {
    return {
        type: types.W06F0003_GET_DECIMAL_QUANTITY,
        params,
        cb
    }
}

export function loadCboEmployees(params, cb) {
    return {
        type: types.W06F0003_GET_CBO_EMPLOYEE,
        params,
        cb
    }
}

export function loadBaseCurrency(params, cb) {
    return {
        type: types.W06F0003_GET_BASE_CURRENCY,
        params,
        cb
    }
}

export function loadMappingBySaleContract(params, cb) {
    return {
        type: types.W06F0003_GET_MAPPING_BY_SALE_CONTRACTS,
        params,
        cb
    }
}