import {
    all
} from 'redux-saga/effects';

import {
    watchLoading,
    watchUploadFile,
    watchLogin,
    watchGetTokenLogin,
    watchChangeThemes,
    watchUpdateFilters,
    watchGetThemes,
    watchRemoveDevice,
    watchAddDevice,
    watchUpdateBadge,
    watchGetBadge,
    watchListNoty,
    watchUpdateStatusNoty,
    watchListUser,
} from "../redux/main/main_sagas";

import GeneralSagas from "../redux/general/general_sagas";

import {
    watchGetAuth,
    watchGetListMenu,
    watchGetVerticalMenu,
    watchList,
    watchGetFormInfo,
    watchGetListBirthday,
} from "../redux/home/home_sagas"

import AdminSagas from "../redux/admin/admin_sagas";
import FileSagas from "../redux/files/file_sagas";
import W91F0001Sagas from "../redux/W0X/W91F0001/W91F0001_sagas";
import W05F0001Sagas from "../redux/W0X/W05F0001/W05F0001_sagas";
import W05F0002Sagas from "../redux/W0X/W05F0002/W05F0002_sagas";
import W05F0003Sagas from "../redux/W0X/W05F0003/W05F0003_sagas";
import W06F0001Sagas from "../redux/W0X/W06F0001/W06F0001_sagas";
import W06F0002Sagas from "../redux/W0X/W06F0002/W06F0002_sagas";
import W06F0003Sagas from "../redux/W0X/W06F0003/W06F0003_sagas";
import W05F0004Sagas from "../redux/W0X/W05F0004/W05F0004_sagas";
import W05F0006Sagas from '../redux/W0X/W05F0006/W05F0006_sagas';
import W06F0004Sagas from "../redux/W0X/W06F0004/W06F0004_sagas";
import W94F1000Sagas from "../redux/W9X/W94F1000/W94F1000_sagas";

import W05F0005Sagas from '../redux/W0X/W05F0005/W05F0005_sagas';
import W05F0007Sagas from '../redux/W0X/W05F0007/W05F0007_sagas';
import W06F0005Sagas from "../redux/W0X/W06F0005/W06F0005_sagas";
import W06F0006Sagas from "../redux/W0X/W06F0006/W06F0006_sagas";
import W06F0007Sagas from "../redux/W0X/W06F0007/W06F0007_sagas";
import W05F0008Sagas from '../redux/W0X/W05F0008/W05F0008_sagas';
import W05F0009Sagas from '../redux/W0X/W05F0009/W05F0009_sagas';
import W05F0010Sagas from '../redux/W0X/W05F0010/W05F0010_sagas';
import W06F0008Sagas from '../redux/W0X/W06F0008/W06F0008_sagas';
import W06F0009Sagas from '../redux/W0X/W06F0009/W06F0009_sagas';
import W06F0010Sagas from '../redux/W0X/W06F0010/W06F0010_sagas';
import W05F0011Sagas from '../redux/W0X/W05F0011/W05F0011_sagas';
import W05F0012Sagas from '../redux/W0X/W05F0012/W05F0012_sagas';
import W05F0013Sagas from '../redux/W0X/W05F0013/W05F0013_sagas';

export default function* rootSaga() {
    yield all([
        watchLoading(),
        watchUploadFile(),
        watchLogin(),
        watchGetTokenLogin(),
        watchChangeThemes(),
        watchUpdateFilters(),
        watchGetThemes(),
        watchRemoveDevice(),
        watchAddDevice(),
        watchUpdateBadge(),
        watchGetBadge(),
        watchListNoty(),
        watchUpdateStatusNoty(),
        watchListUser(),

        FileSagas(),

        //Admin
        AdminSagas(),

        //Generals
        GeneralSagas(),

        //W91F0001
        W91F0001Sagas(),

        //W05F0001
        W05F0001Sagas(),

        //W05F0002
        W05F0002Sagas(),

        //W05F0003
        W05F0003Sagas(),

        // W05F0007
        W05F0007Sagas(),
        // W05F0008
        W05F0008Sagas(),
        //W05F0009
        W05F0009Sagas(),
        W05F0010Sagas(),
        W05F0011Sagas(),
        W05F0012Sagas(),
        W05F0013Sagas(),

        //W06F0001
        W06F0001Sagas(),

        //W06F0002
        W06F0002Sagas(),

        //W06F0003
        W06F0003Sagas(),
        //W05F0006
        W05F0006Sagas(),

        //W05F0001

        //W05F0001
        W05F0004Sagas(),

        //W06F0004
        W06F0004Sagas(),
        //W94F1000
        W94F1000Sagas(),

        //W06F0005
        W06F0005Sagas(),
        //W06F0006
        W06F0006Sagas(),
        //W06F0007
        W06F0007Sagas(),

        W06F0008Sagas(),
        W06F0009Sagas(),
        W06F0010Sagas(),

        //Home
        watchGetAuth(),
        watchGetListMenu(),
        watchGetVerticalMenu(),
        watchList(),
        watchGetFormInfo(),
        watchGetListBirthday(),

        //W05
        W05F0005Sagas(),
    ].flat(1))
}
