/**
 * @copyright 2020 @ DigiNet
 * @author ANHTAI
 * @create 06/08/2020
 * @Example
 */
import { types } from "./W91F0001_actions";

const initialState = {
    objectTypeIDBefore: "",
    timeKeepingList: [],
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.W91F0001_GET_TIMEKEEPINGLIST_SUCCESS:
            const objectTypeIDBefore = action.param.ObjectTypeID;
            if (action.param.skip !== 0 || action.param.limit > 10) return { ...state, objectTypeIDBefore };
            return { ...state, timeKeepingList: action.data, objectTypeIDBefore };
        default:
            return state;
    }
}
