import {delay} from 'redux-saga';
import {put, takeLatest, take, cancel} from 'redux-saga/effects';
import {types} from "./home_actions";
import Api from '../../services/api';
import Config from "../../config";

//##############################
export function* getAuth(data) {
    try {
        yield delay(300);
        const response =yield Api.put('/auth/link', data.params);
        if(response && response.data) {
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('get auth error');
    }
}

export function* watchGetAuth() {
    while(true) {
        const watcher = yield takeLatest(types.GET_AUTH, getAuth);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}
//##############################
//##############################
export function* getList(data) {
    // console.log(data);
    try {
        yield delay(300);
        const list = yield Api.get('https://5d2fd45028465b00146aa88c.mockapi.io/api/v1/dates');
        if(list){
            yield put({type: types.LIST + '_SUCCESS', payload: list}); //đi qua lieu vao reducer
            data.cb && data.cb(null, list);
            return;
        }
        else{
            data.cb && data.cb(list, null);
        }
    }
    catch (e) {
        data.cb && data.cb(null,null);
    }
}

export function* watchList() {
    while (true){
        const watcher = yield takeLatest(types.LIST,getList);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}
//##############################

//##############################

export function* getListMenu(data) {
    // console.log(data);
    try {
        yield delay(300);
        const list = yield Api.put('/w00f0000/get-list-menu', data.params);
        if(list){
            // yield put({type: types.LIST_ESS + '_SUCCESS', payload: list}); //đi qua lieu vao reducer
            data.cb && data.cb(null, list.data);
            return;
        }
        else{
            data.cb && data.cb(list, null);
        }
    }
    catch (e) {
        data.cb && data.cb(null,null);
    }
}

export function* watchGetListMenu() {
    while (true){
        const watcher = yield takeLatest(types.LIST_LIST_MENU, getListMenu);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}
//##############################

//##############################
export function* getVerticalMenu(data) {
    // console.log(data);
    try {
        yield delay(300);
        const list = yield Api.put('/w00f0000/get-vertical-menu', data.params);
        if(list && list.data && list.code === 200){
            // yield put({type: types.LIST_ESS + '_SUCCESS', payload: list}); //đi qua lieu vao reducer
            data.cb && data.cb(null, list.data);
        }
        else{
            data.cb && data.cb(list, null);
        }
    }
    catch (e) {
        data.cb && data.cb(null,null);
    }
}

export function* watchGetVerticalMenu() {
    while (true){
        const watcher = yield takeLatest(types.LIST_VERTICAL_MENU, getVerticalMenu);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}
//##############################

//##############################
export function* getFormInfo(data) {
    // console.log(data);
    try {
        yield delay(300);
        const list = yield Api.put('/w00f1000/form-data',{Language:Config.language});
        if(list && list.data && list.code === 200){
            // yield put({type: types.LIST_ESS + '_SUCCESS', payload: list}); //đi qua lieu vao reducer
            data.cb && data.cb(null, list.data);
        }
        else{
            data.cb && data.cb(list, null);
        }
    }
    catch (e) {
        data.cb && data.cb(null,null);
    }
}

export function* watchGetFormInfo() {
    while (true){
        const watcher = yield takeLatest(types.LIST_FORM_INFO,getFormInfo);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}
//##############################

//##############################
export function* getListBirthday(data) {
    // console.log(data);
    try {
        yield delay(300);
        const list = yield Api.put('/W00F1000/get-birthday-in-week',{Language:Config.language});
        if(list && list.data && list.code === 200){
            yield put({type: types.LIST_BIRTHDAY + '_SUCCESS', payload: list.data}); //đi qua lieu vao reducer
            data.cb && data.cb(null, list.data);
        }
        else{
            data.cb && data.cb(list, null);
        }
    }
    catch (e) {
        data.cb && data.cb(null,null);
    }
}

export function* watchGetListBirthday() {
    while (true){
        const watcher = yield takeLatest(types.LIST_BIRTHDAY,getListBirthday);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}
//##############################
