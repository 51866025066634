/**
 * @copyright 2019 @ DigiNet
 * @author tranghoang
 * @create 2019/08/23 21:26
 * @update 2019/08/23 21:26
 */

import Api                             from '../../services/api';
import Config                          from '../../config';
import {delay}                         from "redux-saga";
import {cancel, put, take, takeLatest} from "redux-saga/effects";
import {types}                         from "../../redux/main/main_actions";
import db                              from "../../components/libs/dexie";
import moment                          from "moment";

export function* loading(cb) {
    try {
        yield delay(300);
        const time = new Date().getTime();
        const isDev = process && process.env && process.env.REACT_APP_DEV_ENV === 'YES';
        let tokenStore = Config.getLocalStorage('TOKENERP', false, true);
        let settingStore = Config.getLocalStorage('SETTINGERP', false, true);
        let profileStore = Config.decryptData(Config.getLocalStorage('PROFILEERP', false, true));
        let menuStore = Config.getLocalStorage('MENUERP');
        let menuTypeStore = Config.getLocalStorage('MENUTYPEERP');
        let themeStore = Config.getLocalStorage('THEMEERP');
        let listUsers = [];
        yield db.userCache.toArray().then((data) => {
            if (data && data.length > 0)
                listUsers = data[0].data;
        });

        Config.language = Config.getLocalStorage('langERP') === 'en' ? "01" : "84";
        Config.color = themeStore && Config.isJson(themeStore) ? JSON.parse(themeStore) : null;
        listUsers = listUsers ? Config.decryptData(listUsers) : null;
        Config.listUsers = listUsers && Config.isJson(listUsers) ? JSON.parse(listUsers) : [];

        if (typeof menuTypeStore !== "undefined") {
            Config.menuType = Number(menuTypeStore);
        }

        if(tokenStore && Config.isJson(tokenStore)) tokenStore = JSON.parse(tokenStore);
        if (tokenStore && tokenStore.id && profileStore && settingStore) {
            settingStore = JSON.parse(settingStore);
            profileStore = JSON.parse(profileStore);
            menuStore = JSON.parse(menuStore);

            Config.menu = menuStore;
            Config.profile = profileStore;
            Config.setting = settingStore;

            const expire = tokenStore.expire || 0;
            // console.log('===== expire: ', expire);
            // console.log('===== time: ', time);
            if (expire > time) {
                yield _updateToken(tokenStore.id)
            } else {
                Config.getToken = true;

                let updateToken = yield Api.put('/auth/check', {token: tokenStore.id});
                if (updateToken && updateToken.data) {
                    yield _updateToken(updateToken.data.token);

                } else {
                    if (isDev) {
                        console.log('set again token for dev');
                        yield _updateToken();
                    } else {
                        console.log('update token error');
                        Config.removeLocalStorage('TOKENERP');
                        Config.removeLocalStorage('SETTINGERP');
                    }
                }
            }
        }
        else {
            Config.getToken = true;

            const tokenResult = yield Api.get('/auth/token');
            if (tokenResult && tokenResult.data) {
                yield _updateToken(tokenResult.token, true);
            } else {
                if (isDev) {
                    console.log('set again token for dev');
                    yield _updateToken(null);
                } else {
                    console.log('get token error');
                    Config.removeLocalStorage('TOKENERP');
                    Config.removeLocalStorage('SETTINGERP');
                }
            }
        }
    }
    catch (e) {
        console.log('loading is error');
    }

}

export function* watchLoading() {
    while (true){
        const watcher = yield takeLatest(types.GET_SETTING,loading);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}

export function* _updateToken(token, isFirst = false) {
    const time   = new Date().getTime();
    const expire = time + 7 * 24 * 60 * 60 * 1000;
    const isDev = process && process.env && process.env.REACT_APP_DEV_ENV === 'YES';
    const tokenLogin = Config.getLocalStorage('TOKENERP_LOGIN');
    if(!token && isDev && tokenLogin){
        Config.setLocalStorage('TOKENERP',  Config.getLocalStorage('TOKENERP_LOGIN'));
        const tokenStore = JSON.parse(Config.getLocalStorage('TOKENERP_LOGIN'));
        token = tokenStore.id;
    }
    if (token) {
        Config.getToken = false;
        Config.token    = {
            id:     token,
            expire: expire
        };
        Config.setLocalStorage('TOKENERP', JSON.stringify(Config.token));

        //get setting..
        yield _getSetting(isDev, isFirst);
    }
}

export function* _getSetting(isDev, isFirst) {
    let stAdminStore = Config.getLocalStorage('STADMINERP', false, true);
    //get themes...
    if (!stAdminStore && !isFirst) {
        const themeLists = yield Api.get('/w00f0000/get-colors');
        if (themeLists && themeLists.data) {
            // console.log("================================ themeLists:", themeLists);
            yield put({
                type:   types.GET_THEME_COMPLETE,
                themes: themeLists.data
            });
            Config.themes = themeLists.data;
        } else {
            console.log('Get themes error');
        }
        Config.color = Config.color ? Config.color : Config.themes[0];
    }

    if (!stAdminStore && !isFirst) {
        yield db.userCache.toArray().then(async data => {
            let allow = data.length <= 0;
            const minutesReload = 15;
            if (data.length > 0) {
                const beforeTime = moment(data[0].time);
                const afterTime = moment();
                allow = afterTime.diff(beforeTime, "minutes") > minutesReload;
            }
            if (allow) {
                let list = await Api.get('/cache/get-users');
                if (list && list.data) {
                    Config.listUsers = list.data;
                    let _data = Config.encryptData(JSON.stringify(list.data));
                    const time = new Date().getTime();
                    await db.userCache.clear().then(() => {
                        db.userCache.add({
                            time: time,
                            data: _data
                        });
                    });
                } else {
                    console.log('Get user cache error');
                }
            }
        });
    }

    const settingLists = yield Api.put('/setting/list',{limit:10000});
    if (settingLists && settingLists.data) {
        // console.log("================================ settingLists:", settingLists);
        Config.setting = settingLists.data;
        const cdnURL = Config.setting && Config.setting.find(setting => setting.name === "CDN_API_URL");
        const cdnSecret = Config.setting && Config.setting.find(setting => setting.name === "CDN_API_SECRET");
        Config.cdn = {
            URL: cdnURL && cdnURL.value ? cdnURL.value : Config.cdn.URL,
            secret: cdnSecret && cdnSecret.value ? cdnSecret.value : Config.cdn.secret
        };
        Config.setLocalStorage('SETTINGERP', JSON.stringify(Config.setting));

        Config.profile = JSON.parse(Config.decryptData(Config.getLocalStorage('PROFILEERP', false, true)));
        //check maintenance..
        const maintenance = yield checkMaintenance();
        // const maintenance = {
        //     "DateFrom": "2021-06-17 08:00:00",
        //     "DateTo": "2021-06-17 17:00:00"
        // };

        //push to reducers
        yield put({type: types.GET_SETTING_COMPLETE,
            token: Config.token,
            setting: Config.setting,
            profile: Config.profile,
            language: Config.language,
            color: Config.color,
            maintenance
        });
    }
    else{
        console.log('Get setting error');
        if (!isDev) Config.removeLocalStorage('TOKENERP');
        Config.removeLocalStorage('SETTINGERP');
    }
}

export function* checkMaintenance() {
    try {
        yield delay(300);
        let res = yield Api.get('/deploy/check');
        return res?.data || null;
    } catch (e) {
        console.log('deploy/check is error');
        return null;
    }
}

export function* changeThemes(data) {
    try {
        yield delay(300);
        Config.color = data.params.theme;
        Config.setLocalStorage('THEMEERP', JSON.stringify(data.params.theme));
        yield put({type: types.CHANGE_THEME_COMPLETE, color: Config.color});
    }
    catch (e) {
        console.log('change theme is error');
    }

}

export function* watchChangeThemes() {
    while (true){
        const watcher = yield takeLatest(types.CHANGE_THEME, changeThemes);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}

export function* updateFilters(data) {
    try {
        yield delay(300);
        yield put({type: types.UPDATE_FILTER_COMPLETE, filters: data.params});
    }
    catch (e) {
        console.log('set filter general is error');
    }

}

export function* watchUpdateFilters() {
    while (true){
        const watcher = yield takeLatest(types.UPDATE_FILTER, updateFilters);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}

export async function* uploadFile(data) {
    try {
        const body = new FormData();
        if(data.mode==='multiple'){
            for(let i=0;i<data.params.length;i++)
                body.append('files', data.params[i]);
        }
        else{
            body.append('files', data.params);
        }
        let result = yield Api.putCDN('/file/upload',body, data.token);
        if (result && result.code && result.message) {
            if (data.cb) data.cb(result, null);
        } else {
            if (data.cb) data.cb(null, result);
        }
    }
    catch (e) {
        console.log('upload is error');
    }

}

export function* watchUploadFile() {
    while (true){
        const watcher = yield takeLatest(types.UPLOAD,uploadFile);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}

export function* login(data) {
    try {
        yield delay(300);
        const modeExchange = process && process.env && process.env.REACT_APP_EXCHANGE;
        let login = null;
        if (modeExchange === "YES") {
            login = yield Api.put('/user/login-exchange', data.params);
        } else {
            login = yield Api.put('/user/login', data.params);
        }
        if (login && login.code && login.message) {
            if (data.cb) data.cb(login, null);
        } else {
            if (data.cb) data.cb(null, login.data);
        }
    }
    catch (e) {
        console.log('login is error');
    }

}

export function* watchLogin() {
    while (true){
        const watcher = yield takeLatest(types.LOGIN,login);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}

export function* getTokenCDN(data) {
    try {
        yield delay(300);
        const response = yield Api.getCDN('/auth/token');
        if (response && response.data) {
            data.cb && data.cb(null, response)
        }
        else{
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get token cdn is error');
    }

}

export function* watchGetTokenLogin() {
    while (true){
        const watcher = yield takeLatest(types.GET_TOKEN_CDN, getTokenCDN);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}

export function* getThemes(data) {
    try {
        yield delay(300);
        const response = yield Api.get('/setting/get-color');
        if (response && response.data) {
            yield put({type: types.GET_THEME_COMPLETE, themes: response.data});
            data.cb && data.cb(null, response)
        }
        else{
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get theme is error');
    }

}

export function* watchGetThemes() {
    while (true){
        const watcher = yield takeLatest(types.GET_THEME, getThemes);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}

export async function getDataForm(data) {
    return new Promise((resolve) => {
        Api.put('/breadcrumbs/get-form-name', data)
            .then(result => {
                return resolve(result);
            });
    } );
}

export function* addDevice(data) {
    try {
        yield delay(300);
        let device = yield Api.put('/device/add', data.params);
        if(device && device.data){
            // yield put({type: types.DEVICE_ADD + '_SUCCESS', payload: device.data});
            data.cb && data.cb(null, device.data);
        }
        else{
            data.cb && data.cb(device, null);
        }
    }
    catch (e) {
        data.cb && data.cb(null,null);
    }
}

export function* watchAddDevice() {
    while (true){
        const watcher = yield takeLatest(types.DEVICE_ADD,addDevice);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}

export function* removeDevice(data) {
    try {
        yield delay(300);
        let device = yield Api.delete('/device/del-by-token', data.params);
        if(device && device.data){
            console.log('remove device success');
            // yield put({type: types.DEVICE_ADD + '_SUCCESS', payload: device.data});
            data.cb && data.cb(null, device.data);
        }
        else{
            data.cb && data.cb(device, null);
        }
    }
    catch (e) {
        data.cb && data.cb(null,null);
    }
}

export function* watchRemoveDevice() {
    while (true){
        const watcher = yield takeLatest(types.DEVICE_DEL,removeDevice);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}

export function* updateBadge(data) {
    try {
        yield delay(300);
        yield put({type: types.UPDATE_BADGE_COMPLETE, badge:data.params});
        data.cb && data.cb();
    }
    catch (e) {
        data.cb && data.cb(null,null);
    }
}

export function* watchUpdateBadge() {
    while (true){
        const watcher = yield takeLatest(types.UPDATE_BADGE,updateBadge);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}

export function* getBadge() {
    try {
        yield delay(300);
        const AppID = Config.env.productID || "";
        let badge = yield Api.get('/notification/badge?AppID=' + AppID);
        // if(badge && badge.data && badge.data.badge){
        yield put({type: types.UPDATE_BADGE_COMPLETE, badge:badge && badge.data && badge.data.badge ? badge.data.badge : 0});
        // }
    }
    catch (e) {
        console.log(e)
    }
}

export function* watchGetBadge() {
    while (true){
        const watcher = yield takeLatest(types.GET_BADGE,getBadge);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}

export function* updateStatusNoty(data) {
    // console.log(data);
    try {
        yield delay(300);
        let update = yield Api.put('/Notification/update-status', data.params);
        if(update && update.data){
            data.cb && data.cb(null, update);
        }
        else{
            data.cb && data.cb(update, null);
        }
    }
    catch (e) {
        data.cb && data.cb(null,null);
    }
}

export function* watchUpdateStatusNoty() {
    while (true){
        const watcher = yield takeLatest(types.UPDATE_STATUS_NOTY,updateStatusNoty);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}

export function* getListNoty(data) {
    try {
        yield delay(300);
        let list = null;
        // yield put({type: types.LIST_NOTY + '_SUCCESS', payload: null});
        if(data.params.type){
            list = yield Api.put('/notification/get-noti-by-type', data.params);
        }
        else{
            list = yield Api.put('/notification/search', data.params);
        }
        if(list && list.data){
            yield put({type: types.LIST_NOTY + '_SUCCESS', payload: list.data}); //đi qua luu vao reducer
            data.cb && data.cb(null, list.data);
        }
        else{
            data.cb && data.cb(list, null);
        }
    }
    catch (e) {
        data.cb && data.cb(null,null);
    }
}

export function* watchListNoty() {
    while (true){
        const watcher = yield takeLatest(types.LIST_NOTY,getListNoty);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}

export function* listUser(data) {
    try {
        yield delay(300);
        let list = yield Api.get('/cache/get-users');
        if(list && list.data){
            Config.listUsers = list.data;
            let _data = Config.encryptData(JSON.stringify(list.data));
            const time = new Date().getTime();
            yield db.userCache.clear().then(() => {
                db.userCache.add({
                    time: time,
                    data: _data
                });
            });
            data.cb && data.cb(null, list.data);
        }
        else{
            // Config.removeLocalStorage('ERP_USER_CACHE');
            data.cb && data.cb(null, null);
        }
    }
    catch (e) {
        console.log("Get user cache error");
        if (!e || !e.code) return false;
        data.cb && data.cb(null,null);
    }
}

export function* watchListUser() {
    while (true){
        const watcher = yield takeLatest(types.LIST_USER_CACHE,listUser);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}
