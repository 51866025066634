/**
 * @copyright 2020 @ DigiNet
 * @author ANHTAI
 * @create 23/12/2020
 * @Example
 */

export const types = {
    W06F0006_GET_REQUEST_LOT: "W06F0006_GET_REQUEST_LOT",
    W06F0006_GET_REQUEST_LOT_SUCCESS: "W06F0006_GET_REQUEST_LOT_SUCCESS",
};

export function getRequestLot(params, cb) {
    return {
        type: types.W06F0006_GET_REQUEST_LOT,
        params,
        cb
    }
}