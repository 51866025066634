import { types } from "./general_actions";
import _ from "lodash";

const initialState = {
    iPermission: null,
    paramsCboGeneralReportBeforce: {},
    getOrgCharts: [],
    getCboDuty: [],
    getCboEmployees: [],
    getCboProjects: [],
    getCboDepartments: [],
    getCboTeam: [],
    getCboWorks: [],
    getCboHandOver: [],
    getCboProjectsW29f2005: [],
    getCboAppStatus: [],
    getCboGeneralReport: [],
    dataSelectModal: [],
    exportBtnState: {}
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.GET_PERMISSION_SUCCESS:
            return {
                ...state,
                iPermission: action.data
            };
        case types.GET_CBO_ORGCHART_SUCCESS:
            return {
                ...state,
                getOrgCharts: action.data
            };
        case types.GET_CBO_DUTY_SUCCESS:
            return {
                ...state,
                getCboDuty: action.data
            };
        case types.GET_CBO_PROJECT_SUCCESS:
            return {
                ...state,
                getCboProjects: action.data
            };
        case types.GENERAL_GET_CBO_DEPARTMENTS_SUCCESS:
            return {
                ...state,
                getCboDepartments: action.data
            };
        case types.GENERAL_GET_CBO_TEAM_SUCCESS:
            return {
                ...state,
                getCboTeam: action.data
            };
        case types.GENERAL_GET_CBO_WORKS_SUCCESS:
            return {
                ...state,
                getCboWorks: action.data
            };
        case types.GET_CBO_EMPLOYEE_SUCCESS:
            return {
                ...state,
                getCboEmployees: action.data
            };
        case types.GET_CBO_APP_STATUS_SUCCESS:
            return {
                ...state,
                getCboAppStatus: action.data
            };
        case types.GENERAL_LOAD_CBO_REPORT_SUCCESS:
            return {
                ...state,
                getCboGeneralReport: action.data,
                paramsCboGeneralReportBeforce: action.params
            };
        case types.GENERAL_GET_COMPANIES_SUCCESS:
        case types.GENERAL_GET_OBJECT_SUCCESS:
            if (action.params.skip !== 0 || !_.isEmpty(action.params.StrSearch)) return { ...state };
            return { ...state, dataSelectModal: action.data };
        default:
            return state;
        case types.GENERAL_SAVE_EXPORT_BTN_STATE_SUCCESS:
            return { ...state, exportBtnState: action.btnExportState };
    }
}
