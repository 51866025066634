/**
 * @copyright 2019 @ DigiNet
 * @author tranghoang
 * @create 2019/08/09 10:37
 * @file src/routes/routes.js
 */

import React, { Component } from 'react';
import { Router, Route, browserHistory, IndexRoute } from 'react-router'
import App from '../container/app';
import Config from '../config';

import ModuleNotFound from '../components/not-found/not-found';
import ErrorPage from '../components/error-500/error-500';
import NotPermission from '../components/not-permission/not-permission';

import Dashboard from '../components/dashboard/dashboard';
import AuthPage from '../components/login/auth';
import W91F0001 from "../components/W0X/W09/W91F0001/W91F0001";
import W05F0001 from "../components/W0X/W05/W05F0001/W05F0001";
import W05F0002 from "../components/W0X/W05/W05F0002/W05F0002";
import W05F0003 from "../components/W0X/W05/W05F0003/W05F0003";
import W06F0001 from "../components/W0X/W06/W06F0001/W06F0001";
import W06F0002 from "../components/W0X/W06/W06F0002/W06F0002";
import W06F0003 from "../components/W0X/W06/W06F0003/W06F0003";
import W05F0005 from "../components/W0X/W05/W05F0005/W05F0005";
import W05F0004 from "../components/W0X/W05/W05F0004/W05F0004";
import W05F0006 from '../components/W0X/W05/W05F0006/W05F0006';
import W06F0004 from "../components/W0X/W06/W06F0004/W06F0004";
import W94F1000 from "../components/W9X/W94/W94F1000/W94F1000";
import W94F1001 from "../components/W9X/W94/W94F1000/W94F1001";
import W94F1002 from "../components/W9X/W94/W94F1000/W94F1002";

import W05F0007 from '../components/W0X/W05/W05F0007/W05F0007';
import W06F0005 from '../components/W0X/W06/W06F0005/W06F0005';
import W06F0006 from '../components/W0X/W06/W06F0006/W06F0006';
import W06F0007 from '../components/W0X/W06/W06F0007/W06F0007';
import W05F0008 from '../components/W0X/W05/W05F0008/W05F0008';
import W05F0009 from '../components/W0X/W05/W05F0009/W05F0009';
import W05F0010 from '../components/W0X/W05/W05F0010/W05F0010';
import W06F0008 from '../components/W0X/W06/W06F0008/W06F0008';
import W06F0009 from '../components/W0X/W06/W06F0009/W06F0009';
import W06F0010 from '../components/W0X/W06/W06F0010/W06F0010';
import W05F0011 from '../components/W0X/W05/W05F0011/W05F0011';
import W05F0012 from '../components/W0X/W05/W05F0012/W05F0012';
import W05F0013 from '../components/W0X/W05/W05F0013/W05F0013';

let oldRouter = '';
class Routes extends Component {

    onRouteUpdate = (e) => {
        window.scrollTo(0, 0);
        if (Config.filters) {
            Config.filters.setFilter(null); //Clear general filters on header
        }
        // ===== DEBUGGER =====
        if (oldRouter !== browserHistory.getCurrentLocation()?.pathname) {
            let dataDebuggerError = Config.debugger.dataDebuggerError;
            Config.debugger.setData(dataDebuggerError);
        }
        oldRouter = browserHistory.getCurrentLocation()?.pathname;
        // ===== DEBUGGER =====
    };

    render() {
        return (
            <Router onUpdate={this.onRouteUpdate} history={browserHistory}>
                <Route path={Config.getRootPath()} component={App}>
                    <IndexRoute component={Dashboard} />

                    <Route path="admin"
                           component={()=><section>Redirecting...</section>}/>

                    {/* W05 */}
                    <Route path="W05F0001" component={W05F0001} />
                    <Route path="W05F0002" component={W05F0002} />
                    <Route path="W05F0003" component={W05F0003} />
                    <Route path="W05F0007" component={W05F0007} />
                    <Route path="W05F0008" component={W05F0008} />
                    <Route path="W05F0009" component={W05F0009} />
                    <Route path="W05F0010" component={W05F0010} />
                    <Route path="W05F0011" component={W05F0011} />
                    <Route path="W05F0012" component={W05F0012} />
                    <Route path="W05F0013" component={W05F0013} />

                    {/* W06 */}
                    <Route path="W06F0001" component={W06F0001} />
                    <Route path="W06F0002" component={W06F0002} />
                    <Route path="W06F0003" component={W06F0003} />
                    <Route path="W06F0004" component={W06F0004} />
                    <Route path="W06F0005" component={W06F0005} />
                    <Route path="W06F0006" component={W06F0006} />
                    <Route path="W06F0007" component={W06F0007} />
                    <Route path="W06F0008" component={W06F0008} />
                    <Route path="W06F0009" component={W06F0009} />
                    <Route path="W06F0010" component={W06F0010} />


                    {/* W05 */}

                    {/* W05 */}
                    <Route path="W05F0004" component={W05F0004} />

                    <Route path="W05F0006" component={W05F0006} />

                    {/* W90 */}
                    <Route path="W91F0001" component={W91F0001} />
                    {/* W94 */}
                    <Route path="W94F1000" component={W94F1000} />
                    <Route path="W94F1001" component={W94F1001} />
                    <Route path="W94F1002" component={W94F1002} />

                    {/* W05 */}
                    <Route path="W05F0005" component={W05F0005} />

                    <Route path="dashboard" component={Dashboard} />
                    <Route path="auth" component={AuthPage} />

                    <Route path="error-page" component={ErrorPage} />

                    <Route path="not-permission" component={NotPermission} />

                    <Route path='*' exact={true} component={ModuleNotFound} />
                </Route>
            </Router>
        );
    };
}

export default Routes;
