/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 10/31/2019
 * @Example 
 */

import { delay } from "redux-saga";
import { cancel, put, take, takeLatest } from "redux-saga/effects";
import Config from "../../config";
import { types } from "../../redux/general/general_actions";
import Api from '../../services/api';

export default function GeneralSagas() {
    return [
        watchGetOrgCharts(),
        watchGetCboProjects(),
        watchGetCboDuty(),
        watchGetCboEmployees(),
        watchGetAttachmentsByTransID(),
        watchExportExcelTemplate(),
        watchGetCboDepartments(),
        watchGetCboTeam(),
        watchGetCboWorks(),
        watchGetCboDepartments(),
        watchGetCboAppStatus(),
        watchgetGridGeneralInformation(),
        watchLoadHistory(),
        watchLoadCboReport(),
        watchExportReport(),
        watchGetInventoriesSelectModal(),
        watchGetCompaniesSelectModal(),
        watchOnSaveStateExportBtn(),
        watchPassParametersExport(),
        watchExportFlexReport(),
        watchGetObjectsSelectModal()
    ];
}

//get organiaztional..
export function* getOrgCharts(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w00f0010/get-structure', data.params);
        if (response && response.data) {
            yield put({ type: types.GET_CBO_ORGCHART_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get organizational error');
    }
}

export function* watchGetOrgCharts() {
    while (true) {
        const watcher = yield takeLatest(types.GET_CBO_ORGCHART, getOrgCharts);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//get duty..
export function* getCboDuty(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w09f2161/load-drd-produty', data.params);
        if (response && response.data) {
            yield put({ type: types.GET_CBO_DUTY_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get cbo duty error');
    }
}

export function* watchGetCboDuty() {
    while (true) {
        const watcher = yield takeLatest(types.GET_CBO_DUTY, getCboDuty);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//get cbo employee..
export function* getCboEmployees(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/standard/load-cbo-employee', data.params);
        if (response && response.data) {
            yield put({ type: types.GET_CBO_EMPLOYEE_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get cbo employees error');
    }
}

export function* watchGetCboEmployees() {
    while (true) {
        const watcher = yield takeLatest(types.GET_CBO_EMPLOYEE, getCboEmployees);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//get cbo project..
export function* getCboProjects(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w09f2161/load-cbo-project', data.params);
        if (response && response.data) {
            yield put({ type: types.GET_CBO_PROJECT_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get cbo projects error');
    }
}

export function* watchGetCboProjects() {
    while (true) {
        const watcher = yield takeLatest(types.GET_CBO_PROJECT, getCboProjects);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//get cbo departments..
export function* getCboDepartments(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/standard/load-cbo-department', data.params);
        if (response && response.data) {
            yield put({ type: types.GENERAL_GET_CBO_DEPARTMENTS_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get cbo departments error');
    }
}

export function* watchGetCboDepartments() {
    while (true) {
        const watcher = yield takeLatest(types.GENERAL_GET_CBO_DEPARTMENTS, getCboDepartments);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//get cbo team..
export function* getCboTeam(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/standard/load-cbo-team', data.params);
        if (response && response.data) {
            yield put({ type: types.GENERAL_GET_CBO_TEAM_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get cbo team error');
    }
}

export function* watchGetCboTeam() {
    while (true) {
        const watcher = yield takeLatest(types.GENERAL_GET_CBO_TEAM, getCboTeam);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//get cbo works..
export function* getCboWorks(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/standard/load-combo-work', data.params);
        if (response && response.data) {
            yield put({ type: types.GENERAL_GET_CBO_WORKS_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get cbo works error');
    }
}

export function* watchGetCboWorks() {
    while (true) {
        const watcher = yield takeLatest(types.GENERAL_GET_CBO_WORKS, getCboWorks);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* getCboAppStatus(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/standard/load-cbo-app-status', data.params);
        if (response && response.data) {
            yield put({ type: types.GET_CBO_APP_STATUS_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get cbo appstatus error');
    }
}

export function* watchGetCboAppStatus() {
    while (true) {
        const watcher = yield takeLatest(types.GET_CBO_APP_STATUS, getCboAppStatus);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//get attachments by TransID..
export function* getAttachmentsByTransID(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/attachment/get-attachment-by-key', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get attachments by transID error');
    }
}

export function* watchGetAttachmentsByTransID() {
    while (true) {
        const watcher = yield takeLatest(types.GENERAL_GET_ATTACHMENTS_BY_TRANSID, getAttachmentsByTransID);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

/**
 * Export excel template...
 * @param data: {params, cb}
 * @param params: {
 *     token,
 *     ReportTypeID
 *     ReportID,
 *     TemplateURL,
 *     isPDF,
 *     Params,
 * }
 * @returns {IterableIterator<Promise<true>|*>}
 */

function checkUrlFileName(filename) {
    //if file name had special character return valid data
    const specialChar = filename.search("UTF-8");
    if (specialChar !== -1) {
        const reFileName = filename.slice(specialChar + 7, filename.length);
        return filename = decodeURIComponent(reFileName);
    }
    return filename; // else return original data 
}

export function* exportExcelTemplate(data) {
    const fileType = data.params?.FileExt;
    try {
        yield delay(300);
        let response = yield Api.putExport('/report/export-report', data.params, fileType);
        if (response && response.ok) {
            let _res = null;
            if (response.type === "json") {
                _res = yield response.data.json();
                if (_res && _res.data) {
                    data.cb && data.cb(null, _res.data);
                } else {
                    data.cb && data.cb(_res, null)
                }
            } else {
                let defaultFileType = ".xls";
                const content = yield response.data.blob();
                const datetime = new Date().getTime();
                if (fileType === "html" || false) defaultFileType = ".doc";
                const _fileName = response.filename ? checkUrlFileName(response.filename) : "data-export-erp-" + datetime + defaultFileType;
                _res = {
                    fileName: _fileName,
                    fileExt: Config.helpers.getExtFile(_fileName),
                    URL: URL.createObjectURL(content),
                };
                if (_res && _res.URL) {
                    data.cb && data.cb(null, _res);
                } else {
                    data.cb && data.cb(_res, null);
                }
            }
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('export excel template error');
    }
}

export function* watchExportExcelTemplate() {
    while (true) {
        const watcher = yield takeLatest(types.GENERAL_EXPORT_EXCEL_TEMPLATE, exportExcelTemplate);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* getGridGeneralInformation(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w75f2000/load-general-information', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data[0])
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get grid error');
    }

}

export function* watchgetGridGeneralInformation() {
    while (true) {
        const watcher = yield takeLatest(types.GET_GRID_GENERAL_INFORMATION, getGridGeneralInformation);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* loadHistory(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/history/search', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('load history error');
    }

}

export function* watchLoadHistory() {
    while (true) {
        const watcher = yield takeLatest(types.GENERAL_LOAD_HISTORY, loadHistory);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* loadCboReport(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/report/get-reports', data.params);
        if (response && response.data) {
            yield put({ type: types.GENERAL_LOAD_CBO_REPORT_SUCCESS, data: response.data, params: data.params });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('load combo report error');
    }

}

export function* watchLoadCboReport() {
    while (true) {
        const watcher = yield takeLatest(types.GENERAL_LOAD_CBO_REPORT, loadCboReport);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* exportReport(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/report/export-report', data.params);
        if (response && response.data) {
            let _res = null;
            if (response.type === "json") {
                _res = yield response.data.json();
                if (_res && _res.data) {
                    data.cb && data.cb(null, _res.data);
                } else {
                    data.cb && data.cb(_res, null)
                }
            } else {
                const content = yield response.data.blob();
                const datetime = new Date().getTime();
                const _fileName = response.filename ? checkUrlFileName(response.filename) : "data-export-hr-" + datetime + '.xls';
                _res = {
                    fileName: _fileName,
                    fileExt: Config.helpers.getExtFile(_fileName),
                    URL: URL.createObjectURL(content),
                };
                if (_res && _res.URL) {
                    data.cb && data.cb(null, _res);
                } else {
                    data.cb && data.cb(_res, null);
                }
            }
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('export report error');
    }
}

export function* watchExportReport() {
    while (true) {
        const watcher = yield takeLatest(types.GENERAL_EXPORT_REPORT, exportReport);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//get select inventories modal data
export function* getInventoriesSelectModal(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w05f0000/get-inventories', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get cbo inventories error');
    }
}

export function* watchGetInventoriesSelectModal() {
    while (true) {
        const watcher = yield takeLatest(types.GENERAL_GET_INVENTORIES, getInventoriesSelectModal);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//get select company modal data
export function* getCompaniesSelectModal(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w05f0005/get-combo-fumigation-company', data.params);
        if (response && response.data) {
            yield put({ type: types.GENERAL_GET_COMPANIES_SUCCESS, data: response.data, params: data.params });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get data companies error');
    }
}

export function* watchGetCompaniesSelectModal() {
    while (true) {
        const watcher = yield takeLatest(types.GENERAL_GET_COMPANIES, getCompaniesSelectModal);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//Save combo UnitData local only
export function* onSaveStateExportBtn(data) {
    yield put({ type: types.GENERAL_SAVE_EXPORT_BTN_STATE_SUCCESS, btnExportState: data.btnExportState });
}

export function* watchOnSaveStateExportBtn() {
    while (true) {
        const watcher = yield takeLatest(types.GENERAL_SAVE_EXPORT_BTN_STATE, onSaveStateExportBtn);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//Check param export
export function* passParametersExport(data) {
    try {
        yield delay(300);
        let response = yield Api.put('/report/passing-parameter', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('pass params report error');
    }
}

export function* watchPassParametersExport() {
    while (true) {
        const watcher = yield takeLatest(types.GENERAL_PASS_PARAM_EXPORT, passParametersExport);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* exportFlexReport(data) {
    try {
        yield delay(300);
        const response = yield Api.putExport('/report/export-flex-report', data.params);
        if (response && response.ok) {
            let _res = null;
            if (response.type === "json") {
                _res = yield response.data.json();
                if (_res && _res.data) {
                    data.cb && data.cb(null, _res.data);
                } else {
                    data.cb && data.cb(_res, null)
                }
            } else {
                const content = yield response.data.blob();
                const datetime = new Date().getTime();
                const _fileName = response.filename ? checkUrlFileName(response.filename) : "data-export-erp-" + datetime + '.xls';
                _res = {
                    fileName: _fileName,
                    fileExt: Config.helpers.getExtFile(_fileName),
                    URL: URL.createObjectURL(content),
                };
                if (_res && _res.URL) {
                    data.cb && data.cb(null, _res);
                } else {
                    data.cb && data.cb(_res, null);
                }
            }
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('export flex report error');
    }
}

export function* watchExportFlexReport() {
    while (true) {
        const watcher = yield takeLatest(types.GENERAL_EXPORT_FLEX_REPORT, exportFlexReport);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//get select object modal data
export function* getObjectsSelectModal(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w91f0001/load-objects', data.params);
        if (response && response.data) {
            yield put({ type: types.GENERAL_GET_OBJECT_SUCCESS, data: response.data, params: data.params });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log("get data objects error");
    }
}

export function* watchGetObjectsSelectModal() {
    while (true) {
        const watcher = yield takeLatest(types.GENERAL_GET_OBJECT, getObjectsSelectModal);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}