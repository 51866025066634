/**
 * @copyright 2020 @ DigiNet
 * @author ANHTAI
 * @create 03/11/2020
 */
export const types = {
    W94F1000_GET_GROUP_LIST: "W94F1000_GET_GROUP_LIST",
    W94F1000_GET_GROUP_LIST_SUCCESS: "W94F1000_GET_GROUP_LIST_SUCCESS",
    W94F1002_GET_CONTROLS_MASTER: "W94F1002_GET_CONTROLS_MASTER",
    W94F1002_GET_CONTROLS_DETAIL_REPORT: 'W94F1002_GET_CONTROLS_DETAIL_REPORT'
};

export function getGroupList(params, cb) {
    return {
        type: types.W94F1000_GET_GROUP_LIST,
        params,
        cb
    }
}

export function getControlMaster(params, cb) { //getControlMaster
    return {
        type: types.W94F1002_GET_CONTROLS_MASTER,
        params,
        cb
    }
}

export function getControlDetailReport(params, cb) { //  getControlsDetailReport
    return {
        type: types.W94F1002_GET_CONTROLS_DETAIL_REPORT,
        params,
        cb
    }
}
