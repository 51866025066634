/**
 * @copyright 2020 @ DigiNet
 * @author TAIAU
 * @create 07/07/2020
 * @Example
 */
import { types } from "./W06F0003_actions";

const initialState = {
    getDecimalQuantity: [],
    getMasterSupplement: [],
    dataCboEmployees: [],
    getBaseCurrency: {},
    paramsMasterSup: {},
    paramsMapping: {},
    dataMapping: {
        rows: [],
        total: null
    }
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.W06F0003_GET_DECIMAL_QUANTITY_SUCCESS:
            return { ...state, getDecimalQuantity: action.data };
        case types.W06F0003_GET_MASTER_SUPPLEMENT_SUCCESS:
            const getMasterSupplement = action.data.filter(item => item.DefaultUse === 1); // Just get DefaultUse = 1
            return { ...state, getMasterSupplement, paramsMasterSup: action.params };
        case types.W06F0003_GET_BASE_CURRENCY_SUCCESS:
            return { ...state, getBaseCurrency: action.data };
        case types.W06F0003_GET_CBO_EMPLOYEE_SUCCESS:
            return { ...state, dataCboEmployees: action.data };
        case types.W06F0003_GET_MAPPING_BY_SALE_CONTRACTS_SUCCESS:
            return { ...state, dataMapping: action.data, paramsMapping: action.params };
        default:
            return state;
    }
}
