/**
 * @copyright 2019 @ DigiNet
 * @author HOANGNAM
 * @create 06/15/2020
 * @Example
 */

import Api from '../../../services/api';
import { put, cancel, take, takeLatest } from "redux-saga/effects";
import { types } from "../../W0X/W06F0001/W06F0001_actions";
import { delay } from "redux-saga";

export default function W06F0001Sagas() {
    return [
        watchGetCboContractTypes(),
        watchGetCboEmployees(),
        watchGetAmountOfContract(),
        watchGetListContracts(),
        watchDeleteItemContract(),
        watchGetCboListObjects(),
        watchGetCboTransType(),
        watchGetCboStatus(),
        watchLoadFormInfoCancel(),
        watchSaveInfoCancel(),
    ];
}

//get cbo contract type...
export function* getCboContractTypes(data) {
    try {
        yield delay(300);
        const response = yield Api.get('/w05f0000/get-combo-contractType', data.params);
        if (response && response.data) {
            yield put({ type: types.W06F0001_LOAD_CBO_CONTRACT_TYPE_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('get cbo contract type error');
    }
}

export function* watchGetCboContractTypes() {
    while (true) {
        const watcher = yield takeLatest(types.W06F0001_LOAD_CBO_CONTRACT_TYPE, getCboContractTypes);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//get cbo employee ...
export function* getCboEmployees(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w05f0000/get-combo-employee', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('get cbo employee error')
    }
}

export function* watchGetCboEmployees() {
    while (true) {
        const watcher = yield takeLatest(types.W06F0001_LOAD_CBO_EMPLOYEE, getCboEmployees);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }

}

//get amount of contract
export function* getAmountOfContract(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w06f0000/get-amount-of-contract', data.params);
        if (response && response.data) {
            yield put({ type: types.W06F0001_LOAD_AMOUNT_OF_CONTRACT_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('get amount of contract error')
    }
}

export function* watchGetAmountOfContract() {
    while (true) {
        const watcher = yield takeLatest(types.W06F0001_LOAD_AMOUNT_OF_CONTRACT, getAmountOfContract);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//get list contract ...
export function* getListContracts(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w06f0000/load-list-contract', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('get list contract error')
    }
}

export function* watchGetListContracts() {
    while (true) {
        const watcher = yield takeLatest(types.W06F0001_LOAD_LIST_CONTRACT, getListContracts);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }

}

// delete item contract
export function* deleteItemContract(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w06f0000/delete-contract', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('delete item contract error');
    }
}

export function* watchDeleteItemContract() {
    while (true) {
        const watcher = yield takeLatest(types.W06F0001_DELETE_ITEM_CONTRACT, deleteItemContract);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

// get cbo list object
export function* getCboListObjects(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w91f0001/load-objects', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('get cbo list object error');
    }
}

export function* watchGetCboListObjects() {
    while (true) {
        const watcher = yield takeLatest(types.W06F0001_LOAD_CBO_LIST_OBJECT, getCboListObjects);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

// get cbo transType
export function* getCboTransType(data) {
    try {
        yield delay(300);
        const response = yield Api.get('/w06f0000/get-combo-transType');
        if (response && response.data) {
            yield put({ type: types.W06F0001_GET_CBO_TRANS_TYPE_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('get cbo transType error');
    }
}

export function* watchGetCboTransType() {
    while (true) {
        const watcher = yield takeLatest(types.W06F0001_GET_CBO_TRANS_TYPE, getCboTransType);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* getCboStatus(data) {
    try {
        yield delay(300);
        const response = yield Api.get('/w06f0000/get-combo-status-cancel');
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('get cbo transType error');
    }
}

export function* watchGetCboStatus() {
    while (true) {
        const watcher = yield takeLatest(types.W06F0001_GET_CBO_STATUS, getCboStatus);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* loadFormInfoCancel(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w06f0000/get-popup-cancel', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('loadFormInfoCancel');
    }
}

export function* watchLoadFormInfoCancel() {
    while (true) {
        const watcher = yield takeLatest(types.W06F0001_LOAD_FORM_INFO_CANCEL, loadFormInfoCancel);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* saveInfoCancel(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w06f0000/save', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('saveInfoCancel');
    }
}

export function* watchSaveInfoCancel() {
    while (true) {
        const watcher = yield takeLatest(types.W06F0001_SAVE_INFO_CANCEL, saveInfoCancel);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}





