/**
 * @copyright 2020 @ DigiNet
 * @author TAIAU
 * @create 07/13/2020
 * @Example
 */
import moment from 'moment';
import Config from "../../../config/index";
import { types } from "./W06F0002_actions";

const initialState = {
    dataCboUnit: {},
    getDecimalQuantity: {},
    getCboContractType: [],
    getCboCurrency: [],
    getCboEmployees: [],
    getCboStatus: [],
    getInventories: [],
    getCboPackingMethod: [],
    getCboBaseCurrency: [],
    getDetailSupplement: [],
    getMasterSupplement: [],
    getCboPackingType: [],
    getContractNo: [],
    dataLoadForm: [],
    oldDataInventories: [],
    getCboPaymentMethod: [],
    paramsMasterSup: {},
    getCboBankAccount: []
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.W06F0002_GET_CBO_CONTRACT_TYPE_SUCCESS:
            return { ...state, getCboContractType: action.data };
        case types.W06F0002_GET_CBO_CURRENCY_SUCCESS:
            return { ...state, getCboCurrency: action.data };
        case types.W06F0002_GET_CBO_EMPLOYEES_SUCCESS:
            return { ...state, getCboEmployees: action.data };
        case types.W06F0002_GET_CBO_STATUS_SUCCESS:
            return { ...state, getCboStatus: action.data };
        case types.W06F0002_GET_CBO_PACKING_METHOD_SUCCESS:
            return { ...state, getCboPackingMethod: action.data };
        case types.W06F0002_GET_INVENTORIES_SUCCESS:
            if (action.skip !== 0) return { ...state };
            return { ...state, getInventories: action.data };
        case types.W06F0002_GET_BASE_CURRENCY_SUCCESS:
            return { ...state, getCboBaseCurrency: action.data };
        case types.W06F0002_GET_DECIMAL_QUANTITY_SUCCESS:
            return { ...state, getDecimalQuantity: action.data };
        case types.W06F0002_GET_DETAIL_SUPPLEMENT_SUCCESS:
            return { ...state, getDetailSupplement: action.data };
        case types.W06F0002_GET_MASTER_SUPPLEMENT_SUCCESS:
            let activeMasterSupplement = [];
            if (action.data.length > 0) activeMasterSupplement = action.data.filter(item => item.DefaultUse === 1);
            return { ...state, getMasterSupplement: activeMasterSupplement, paramsMasterSup: action.params };
        case types.W06F0002_GET_CBO_PACKING_TYPE_SUCCESS:
            return { ...state, getCboPackingType: action.data };
        case types.W06F0002_GET_CONTRACT_NO_SUCCESS:
            return { ...state, getContractNo: action.data };
        case types.W06F0002_GET_CBO_PAYMENT_METHOD_SUCCESS:
            return { ...state, getCboPaymentMethod: action.data };
        case types.W06F0002_LOAD_FORM_DATA_SUCCESS:
            if (!action.data.inventories && !action.data.master) return;
            const { inventories, master, attachments } = action.data;
            //Format Time Master 
            Object.keys(master).forEach(dataMaster => {
                if (Config.isValidDateTime(master[dataMaster])) {
                    master[dataMaster] = moment(master[dataMaster]).format('YYYY-MM-DD')
                }
            });
            let oldDataInventories = inventories.map(dataInven => {
                Object.keys(dataInven).forEach(key => {
                    if (Config.isValidDateTime(dataInven[key])) {
                        dataInven[key] = moment(dataInven[key]).format('YYYY-MM-DD')
                    }
                });
                return dataInven;
            });
            return { ...state, oldDataMaster: master, oldDataAttachments: attachments, oldDataInventories };
        case types.W06F0002_GET_DATA_FORM_VIEW_SUCCESS:
            if (!action.data.master && !action.data.inventories) return;
            const { master: masterDataView, inventories: inventoriesView } = action.data;
            //Format Time Master 
            Object.keys(masterDataView).forEach(dataMaster => {
                if (Config.isValidDateTime(masterDataView[dataMaster])) {
                    masterDataView[dataMaster] = moment(masterDataView[dataMaster]).format('DD/MM/YYYY')
                }
            });
            const inventoriesDataView = inventoriesView.map(dataInven => {
                Object.keys(dataInven).forEach(key => {
                    if (Config.isValidDateTime(dataInven[key])) {
                        dataInven[key] = moment(dataInven[key]).format('YYYY-MM-DD')
                    }
                });
                return dataInven;
            });
            return { ...state, loadFormView: masterDataView, loadFormInvenView: inventoriesDataView };
        case types.W06F0002_SAVE_CBO_UNIT_SUCCESS:
            return { ...state, dataCboUnit: action.data };
        case types.W06F0002_GET_CBO_BANK_ACCOUNT_SUCCESS:
            return { ...state, getCboBankAccount: action.data };
        default:
            return state;
    }
}
