import { combineReducers } from 'redux';
import Main from '../redux/main/main_reducers';
import Home from '../redux/home/home_reducers';
import General from '../redux/general/general_reducers';
import W91F0001 from '../redux/W0X/W91F0001/W91F0001_reducers';
import W05F0001 from '../redux/W0X/W05F0001/W05F0001_reducers';
import W05F0002 from '../redux/W0X/W05F0002/W05F0002_reducers';
import W05F0003 from '../redux/W0X/W05F0003/W05F0003_reducers';
import W06F0001 from '../redux/W0X/W06F0001/W06F0001_reducers';
import W06F0002 from '../redux/W0X/W06F0002/W06F0002_reducers';
import W06F0003 from '../redux/W0X/W06F0003/W06F0003_reducers';
import W05F0005 from '../redux/W0X/W05F0005/W05F0005_reducers';
import W05F0004 from "../redux/W0X/W05F0004/W05F0004_reducers";
import W05F0006 from '../redux/W0X/W05F0006/W05F0006_reducers';
import W94F1000 from '../redux/W9X/W94F1000/W94F1000_reducers';
import W06F0005 from '../redux/W0X/W06F0005/W06F0005_reducers';
import W06F0006 from '../redux/W0X/W06F0006/W06F0006_reducers';
import W06F0007 from '../redux/W0X/W06F0007/W06F0007_reducers';
import W05F0012 from '../redux/W0X/W05F0012/W05F0012_reducers';

export default combineReducers({
    main: Main,
    home: Home,
    general: General,
    W91F0001: W91F0001,
    W05F0001: W05F0001,
    W05F0002: W05F0002,
    W05F0003: W05F0003,
    W06F0001: W06F0001,
    W06F0002: W06F0002,
    W06F0003: W06F0003,
    W05F0005: W05F0005,
    W05F0004: W05F0004,

    W05F0006: W05F0006,
    W94F1000: W94F1000,
    W06F0005: W06F0005,
    W06F0006: W06F0006,
    W06F0007: W06F0007,
    W05F0012: W05F0012,

});
