/**
 * @copyright 2020 @ DigiNet
 * @author ANHTAI
 * @create 18/11/2020
 * @Example
 */
import { types } from "./W06F0005_actions";

const initialState = {
    getFlexCaption: [],
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.W06F0005_GET_FLEX_CAPTION_SUCCESS:
            const getFlexCaption = action.data.filter(item => item.Disabled === 0);
            return { ...state, getFlexCaption };
        default:
            return state;
    }
}
