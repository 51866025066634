import { types } from './W06F0001_actions';

const initialState = {
    getCboContractTypes: [],
    getCboTransType: [],
    getAmountOfContract: [],
    getCboTransTypes: []
};

export default function (state = initialState, action = {}) {

    switch (action.type) {
        case types.W06F0001_LOAD_CBO_CONTRACT_TYPE_SUCCESS:
            return {
                ...state,
                getCboContractTypes: action.data
            };
        case types.W06F1001_GET_CBO_TRANS_TYPE_SUCCESS:
            return {
                ...state,
                getCboTransType: action.data
            };
        case types.W06F0001_LOAD_AMOUNT_OF_CONTRACT_SUCCESS:
            return {
                ...state,
                getAmountOfContract: action.data
            };
        case types.W06F0001_GET_CBO_TRANS_TYPE_SUCCESS:
            return {
                ...state,
                getCboTransTypes: action.data
            };
        default:
            return state;
    }
}