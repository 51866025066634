/**
 * @copyright 2020 @ DigiNet
 * @author ANHTAI
 * @create 18/11/2020
 * @Example
 */

import { delay } from "redux-saga";
import { cancel, put, take, takeEvery, takeLatest } from "redux-saga/effects";
import Api from '../../../services/api';
import { types } from "./W06F0005_actions";

export default function W06F0005Sagas() {
    return [
        watchGetListObjects(),
        watchGetInventories(),
        watchGetMapping(),
        watchGetFlexMapping(),
        watchGetChosenMapping()
    ];
}
export function* getListObjects(data) {
    try {
        yield delay(300);
        const res = yield Api.put("/w91f0001/load-objects", data.params);
        if (res && res.data) {
            data.cb && data.cb(null, res.data);
            return;
        } else {
            data.cb && data.cb(res, null);
        }
    }
    catch (e) {
        console.log("get list objecs error");
    }
}

export function* watchGetListObjects() {
    while (true) {
        const watcher = yield takeEvery(
            types.W06F0005_GET_LIST_OBJECTS,
            getListObjects
        );
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}
export function* getInventories(data) {
    try {
        yield delay(300);
        const res = yield Api.put("/w05f0000/get-inventories", data.params);
        if (res && res.data) {
            data.cb && data.cb(null, res.data);
            return;
        } else {
            data.cb && data.cb(res, null);
        }
    }
    catch (e) {
        console.log("get inventories error");
    }
}

export function* watchGetInventories() {
    while (true) {
        const watcher = yield takeLatest(
            types.W06F0005_GET_LIST_INVENTORIES,
            getInventories
        );
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}
export function* getMapping(data) {
    try {
        yield delay(300);
        const res = yield Api.put("/w06f0005/get-mapping", data.params);
        if (res && res.data) {
            data.cb && data.cb(null, res.data);
            return;
        } else {
            data.cb && data.cb(res, null);
        }
    }
    catch (e) {
        console.log("get mapping error");
    }
}

export function* watchGetMapping() {
    while (true) {
        const watcher = yield takeLatest(
            types.W06F0005_GET_MAPPING,
            getMapping
        );
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getFlexMapping(data) {
    try {
        yield delay(300);
        const res = yield Api.put("/w05f0007/get-flex-caption", data.params);
        if (res && res.data) {
            yield put({ type: types.W06F0005_GET_FLEX_CAPTION_SUCCESS, data: res.data });
            data.cb && data.cb(null, res.data);
            return;
        } else {
            data.cb && data.cb(res, null);
        }
    }
    catch (e) {
        console.log("get flex mapping error");
    }
}

export function* watchGetFlexMapping() {
    while (true) {
        const watcher = yield takeLatest(types.W06F0005_GET_FLEX_CAPTION, getFlexMapping);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getChosenMapping(data) {
    try {
        yield delay(300);
        const res = yield Api.put("/w06f0005/load-grid-delivery", data.params);
        if (res && res.data) {
            data.cb && data.cb(null, res.data);
            return;
        } else {
            data.cb && data.cb(res, null);
        }
    }
    catch (e) {
        console.log("get chosen mapping error");
    }
}

export function* watchGetChosenMapping() {
    while (true) {
        const watcher = yield takeLatest(types.W06F0005_GET_CHOSEN_MAPPING, getChosenMapping);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}