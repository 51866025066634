import Api from "../../../services/api";

/**
 * @copyright 2019 @ DigiNet
 * @author ANHTAI
 * @create 07/13/2020
 * @Example
 */

export const types = {
    W06F0001_LOAD_CBO_CONTRACT_TYPE: 'W06F0001_LOAD_CBO_CONTRACT_TYPE',
    W06F0001_LOAD_CBO_CONTRACT_TYPE_SUCCESS: 'W06F0001_LOAD_CBO_CONTRACT_TYPE_SUCCESS',
    W06F0001_LOAD_CBO_EMPLOYEE: 'W06F0001_LOAD_CBO_EMPLOYEE',
    W06F0001_LOAD_CBO_EMPLOYEE_SUCCESS: 'W06F0001_LOAD_CBO_EMPLOYEE_SUCCESS',
    W06F0001_LOAD_AMOUNT_OF_CONTRACT: 'W06F0001_LOAD_AMOUNT_OF_CONTRACT',
    W06F0001_LOAD_AMOUNT_OF_CONTRACT_SUCCESS: 'W06F0001_LOAD_AMOUNT_OF_CONTRACT_SUCCESS',
    W06F0001_LOAD_LIST_CONTRACT: 'W06F0001_LOAD_LIST_CONTRACT',
    W06F0001_LOAD_LIST_CONTRACT_SUCCESS: 'W06F0001_LOAD_LIST_CONTRACT_SUCCESS',
    W06F0001_DELETE_ITEM_CONTRACT: "W06F0001_DELETE_ITEM_CONTRACT",
    W06F0001_DELETE_ITEM_CONTRACT_SUCCESS: "W06F0001_DELETE_ITEM_CONTRACT_SUCCESS",
    W06F0001_LOAD_CBO_LIST_OBJECT: "W06F0001_LOAD_CBO_LIST_OBJECT",
    W06F0001_LOAD_CBO_LIST_OBJECT_SUCCESS: "W06F0001_LOAD_CBO_LIST_OBJECT_SUCCESS",
    W06F0001_GET_CBO_TRANS_TYPE: "W06F0001_GET_CBO_TRANS_TYPE",
    W06F0001_GET_CBO_TRANS_TYPE_SUCCESS: "W06F0001_GET_CBO_TRANS_TYPE_SUCCESS",

    //Infocancel
    W06F0001_GET_CBO_STATUS: "W06F0001_GET_CBO_STATUS",
    W06F0001_LOAD_FORM_INFO_CANCEL: "W06F0001_LOAD_FORM_INFO_CANCEL",
    W06F0001_SAVE_INFO_CANCEL: "W06F0001_SAVE_INFO_CANCEL",
}

export function addHistory(params, cb) {
    return async () => {
        await Api.put('/history/add', params)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    cb && cb(400);
                }
                else {
                    cb && cb(null)
                }
            })
    }
}

export function getCboContractTypes(params, cb) {
    return {
        type: types.W06F0001_LOAD_CBO_CONTRACT_TYPE,
        params,
        cb
    }

}

export function getCboEmployees(params, cb) {
    return {
        type: types.W06F0001_LOAD_CBO_EMPLOYEE,
        params,
        cb
    }
}

export function getAmountOfContract(params, cb) {
    return {
        type: types.W06F0001_LOAD_AMOUNT_OF_CONTRACT,
        params,
        cb
    }
}

export function getListContracts(params, cb) {
    return {
        type: types.W06F0001_LOAD_LIST_CONTRACT,
        params,
        cb
    }
}

export function deleteItemContract(params, cb) {
    return {
        type: types.W06F0001_DELETE_ITEM_CONTRACT,
        params,
        cb
    }
}

export function getCboListObjects(params, cb) {
    return {
        type: types.W06F0001_LOAD_CBO_LIST_OBJECT,
        params,
        cb
    }
}

export function getCboTransType(cb) {
    return {
        type: types.W06F0001_GET_CBO_TRANS_TYPE,
        cb
    }
}

export function getCboStatus(params, cb) {
    return {
        type: types.W06F0001_GET_CBO_STATUS,
        params,
        cb
    }
}

export function loadFormInfoCancel(params, cb) {
    return {
        type: types.W06F0001_LOAD_FORM_INFO_CANCEL,
        params,
        cb
    }
}

export function saveInfoCancel(params, cb) {
    return {
        type: types.W06F0001_SAVE_INFO_CANCEL,
        params,
        cb
    }
}
