
export const types = {
    GET_SETTING: 'GET_SETTING',
    GET_SETTING_COMPLETE: 'GET_SETTING_COMPLETE',

    UPLOAD: 'UPLOAD',

    LOGIN: 'LOGIN',

    GET_TOKEN_CDN: 'GET_TOKEN_CDN',

    GET_DATA_FORM: 'GET_DATA_FORM',

    GET_THEME: 'GET_THEME',
    GET_THEME_COMPLETE: 'GET_THEME_COMPLETE',

    CHANGE_THEME: 'CHANGE_THEME',
    CHANGE_THEME_COMPLETE: 'CHANGE_THEME_COMPLETE',

    UPDATE_FILTER: 'UPDATE_FILTER',
    UPDATE_FILTER_COMPLETE: 'UPDATE_FILTER_COMPLETE',

    UPDATE_BADGE: 'UPDATE_BADGE',
    GET_BADGE: 'GET_BADGE',
    UPDATE_BADGE_COMPLETE: 'UPDATE_BADGE_COMPLETE',
    DEVICE_ADD: 'DEVICE_ADD',
    DEVICE_ADD_SUCCESS: 'DEVICE_ADD_SUCCESS',
    DEVICE_DEL: 'DEVICE_DEL',
    UPDATE_STATUS_NOTY: 'UPDATE_STATUS_NOTY',
    LIST_NOTY: 'LIST_NOTY',
    LIST_NOTY_SUCCESS: 'LIST_NOTY_SUCCESS',
    LIST_USER_CACHE: 'LIST_USER_CACHE',
};

export function loading(cb) {
    return{
        type: types.GET_SETTING,
        cb
    }
}

export function changeTheme(params) {
    return{
        type: types.CHANGE_THEME,
        params
    }
}

export function updateFilters(params) {
    return{
        type: types.UPDATE_FILTER,
        params
    }
}

export function upload(params, mode, token, cb) {
    return{
        type: types.UPLOAD,
        params,
        mode,
        token,
        cb
    }
}

export function login(params, cb) {
    return{
        type: types.LOGIN,
        params,
        cb
    }
}

export function getTokenCDN(cb) {
    return{
        type: types.GET_TOKEN_CDN,
        cb
    }
}

export function getTheme(cb) {
    return{
        type: types.GET_THEME,
        cb
    }
}

export function updateBadge(params,cb) {
    return{
        type: types.UPDATE_BADGE,
        params,
        cb
    }
}

export function getBadge() {
    return{
        type: types.GET_BADGE,
    }
}

export function addDevice(params,cb) {
    return{
        type: types.DEVICE_ADD,
        params,
        cb
    }
}

export function delDevice(params,cb) {
    return{
        type: types.DEVICE_DEL,
        params,
        cb
    }
}

export function updateStatusNoty(params,cb) {
    return {
        type: types.UPDATE_STATUS_NOTY,
        params,
        cb,
    }
}

export function getListNotification(params,cb) {
    return {
        type: types.LIST_NOTY,
        params,
        cb,
    }
}
export function listUser(cb) {
    return {
        type: types.LIST_USER_CACHE,
        cb,
    }
}
