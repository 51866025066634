
import { delay } from "redux-saga";
import { cancel, put, take, takeEvery, takeLatest } from "redux-saga/effects";
import Api from '../../../services/api';
import { types } from "./W06F0007_actions";

export default function W06F0007Sagas() {
    return [
        watchGetDecimals(),
        watchGetCation(),
        watchGetCbDelivery(),
        watchGetcbUnitPack(),
        watchGetcbWarehousePacking(),
        watchGetcbDeliveryStatus(),
        watchGetCbMarking(),
        watchGetFormEdit(),
        watchGetListObjects(),
        watchGetInventories(),
        watchOnRefuse(),
        watchSaveApproval(),
    ];
}

export function* getDecimals(data) {
    try {
        yield delay(300);
        const response = yield Api.get('/w05f0007/get-decimal-quantity');
        if (response && response.data) {
            yield put({ type: types.W06F0007_GET_DECIMALS_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('get decimals error')
    }
}

export function* watchGetDecimals() {
    while (true) {
        const watcher = yield takeLatest(types.W06F0007_GET_DECIMALS, getDecimals);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }

}

export function* getCation(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w05f0007/get-flex-caption', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('get caption error')
    }
}

export function* watchGetCation() {
    while (true) {
        const watcher = yield takeEvery(types.W06F0007_GET_CAPTION, getCation);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }

}

export function* getCbDelivery(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w05f0007/get-combo-vessel', data.params);
        if (response && response.data) {
            yield put({ type: types.W06F0007_GET_CB_DELIVERY_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('get cbo delivery error')
    }
}

export function* watchGetCbDelivery() {
    while (true) {
        const watcher = yield takeLatest(types.W06F0007_GET_CB_DELIVERY, getCbDelivery);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }

}

export function* getcbUnitPack(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w91f0001/load-objects', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('get cbo unit pack error')
    }
}

export function* watchGetcbUnitPack() {
    while (true) {
        const watcher = yield takeLatest(types.W06F0007_GET_CB_UNITPACK, getcbUnitPack);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }

}

export function* getcbWarehousePacking(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w05f0007/get-combo-warehouse-packing', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('get cbo ware house packing error')
    }
}

export function* watchGetcbWarehousePacking() {
    while (true) {
        const watcher = yield takeLatest(types.W06F0007_GET_CB_WAREHOUSE_PACKING, getcbWarehousePacking);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }

}

export function* getcbDeliveryStatus(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w05f0007/get-combo-vessel-status', data.params);
        if (response && response.data) {
            yield put({ type: types.W06F0007_GET_CB_DELIVERY_STATUS_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('get cbo delivery error')
    }
}

export function* watchGetcbDeliveryStatus() {
    while (true) {
        const watcher = yield takeLatest(types.W06F0007_GET_CB_DELIVERY_STATUS, getcbDeliveryStatus);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }

}

export function* getCbMarking(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w05f0000/get-combo-marking', data.params);
        if (response && response.data) {
            yield put({ type: types.W06F0007_GET_CB_MARKING_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('get cbo marking error')
    }
}

export function* watchGetCbMarking() {
    while (true) {
        const watcher = yield takeLatest(types.W06F0007_GET_CB_MARKING, getCbMarking);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }

}

export function* getFormEdit(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w06f0007/load-form', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('get form edit error')
    }
}

export function* watchGetFormEdit() {
    while (true) {
        const watcher = yield takeLatest(types.W06F0007_GET_FORM_EDIT, getFormEdit);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }

}

export function* getListObjects(data) {
    try {
        yield delay(300);
        const res = yield Api.put("/w91f0001/load-objects", data.params);
        if (res && res.data) {
            data.cb && data.cb(null, res.data);
            return;
        } else {
            data.cb && data.cb(res, null);
        }
    } catch (e) {
        console.log('get list objects error')
    }
}

export function* watchGetListObjects() {
    while (true) {
        const watcher = yield takeLatest(
            types.W06F0007_GET_LIST_OBJECTS,
            getListObjects
        );
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getInventories(data) {
    try {
        yield delay(300);
        const res = yield Api.put("/w05f0000/get-inventories", data.params);
        if (res && res.data) {
            data.cb && data.cb(null, res.data);
            return;
        } else {
            data.cb && data.cb(res, null);
        }
    } catch (e) {
        console.log('get inventories error')
    }
}

export function* watchGetInventories() {
    while (true) {
        const watcher = yield takeLatest(
            types.W06F0007_GET_LIST_INVENTORIES,
            getInventories
        );
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* onRefuse(data) {
    try {
        yield delay(300);
        const res = yield Api.put("/w06f0007/refuse", data.params);
        if (res && res.data) {
            data.cb && data.cb(null, res.data);
            return;
        } else {
            data.cb && data.cb(res, null);
        }
    } catch (e) {
        console.log('refuse error')
    }
}

export function* watchOnRefuse() {
    while (true) {
        const watcher = yield takeLatest(types.W06F0007_ON_REFUSE, onRefuse);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* saveApproval(data) {
    try {
        yield delay(300);
        const res = yield Api.put("/w06f0007/save-approval", data.params);
        if (res && res.data) {
            data.cb && data.cb(null, res.data);
            return;
        } else {
            data.cb && data.cb(res, null);
        }
    } catch (e) {
        console.log('save approval error')
    }
}

export function* watchSaveApproval() {
    while (true) {
        const watcher = yield takeLatest(types.W06F0007_SAVE_APPROVAL, saveApproval);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}