/**
 * @copyright 2020 @ DigiNet
 * @author ANHTAI
 * @create 23/12/2020
 * @Example
 */
import { types } from "./W06F0006_actions";

const initialState = {
    requestLotData: [],
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.W06F0006_GET_REQUEST_LOT_SUCCESS:
            return { ...state, requestLotData: action.data };
        default:
            return state;
    }
}
