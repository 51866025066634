import { types } from './W06F0007_actions';

const initialState = {
    cbMarking: [],
    cbDeliveryStatus: [],
    cbDelivery: [],
    decimals: []
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.W06F0007_GET_CB_MARKING_SUCCESS:
            return { ...state, cbMarking: action.data };
        case types.W06F0007_GET_CB_DELIVERY_STATUS_SUCCESS:
            return { ...state, cbDeliveryStatus: action.data };
        case types.W06F0007_GET_CB_DELIVERY_SUCCESS:
            return { ...state, cbDelivery: action.data };
        case types.W06F0007_GET_DECIMALS_SUCCESS:
            return { ...state, decimals: action.data };
        default:
            return state;
    }
}