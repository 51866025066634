/**
 * @copyright 2020 @ DigiNet
 * @author ANHTAI
 * @create 23/12/2020
 * @Example
 */

import { delay } from "redux-saga";
import { cancel, take, takeLatest } from "redux-saga/effects";
import Api from '../../../services/api';
import { types } from "./W06F0006_actions";

export default function W06F0006Sagas() {
    return [
        watchGetRequestLot(),
    ];
}

export function* getRequestLot(data) {
    try {
        yield delay(300);
        const res = yield Api.put("/w06f0006/load-grid", data.params);
        if (res && res.data) {
            data.cb && data.cb(null, res.data);
            return;
        } else {
            data.cb && data.cb(res, null);
        }
    }
    catch (e) {
        console.log("get list objecs error");
    }
}

export function* watchGetRequestLot() {
    while (true) {
        const watcher = yield takeLatest(types.W06F0006_GET_REQUEST_LOT, getRequestLot);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}