/**
 * @copyright 2020 @ DigiNet
 * @author TAIAU
 * @create 07/13/2020
 * @Example
 */

import Api from '../../../services/api';
export const types = {
    W06F0002_GET_CBO_CONTRACT_TYPE: "W06F0002_GET_CBO_CONTRACT_TYPE",
    W06F0002_GET_CBO_CONTRACT_TYPE_SUCCESS: "W06F0002_GET_CBO_CONTRACT_TYPE_SUCCESS",
    W06F0002_GET_CBO_CURRENCY: "W06F0002_GET_CBO_CURRENCY",
    W06F0002_GET_CBO_CURRENCY_SUCCESS: "W06F0002_GET_CBO_CURRENCY_SUCCESS",
    W06F0002_GET_CBO_EMPLOYEES: "W06F0002_GET_CBO_EMPLOYEES",
    W06F0002_GET_CBO_EMPLOYEES_SUCCESS: "W06F0002_GET_CBO_EMPLOYEES_SUCCESS",
    W06F0002_GET_CBO_STATUS: "W06F0002_GET_CBO_STATUS",
    W06F0002_GET_CBO_STATUS_SUCCESS: "W06F0002_GET_CBO_STATUS_SUCCESS",
    W06F0002_GET_INVENTORIES: "W06F0002_GET_INVENTORIES",
    W06F0002_GET_INVENTORIES_SUCCESS: "W06F0002_GET_INVENTORIES_SUCCESS",
    W06F0002_GET_CBO_PACKING_METHOD: "W06F0002_GET_CBO_PACKING_METHOD",
    W06F0002_GET_CBO_PACKING_METHOD_SUCCESS: "W06F0002_GET_CBO_PACKING_METHOD_SUCCESS",
    W06F0002_GET_BASE_CURRENCY: "W06F0002_GET_BASE_CURRENCY",
    W06F0002_GET_BASE_CURRENCY_SUCCESS: "W06F0002_GET_BASE_CURRENCY_SUCCESS",
    W06F0002_GET_DECIMAL_QUANTITY: "W06F0002_GET_DECIMAL_QUANTITYS",
    W06F0002_GET_DECIMAL_QUANTITY_SUCCESS: "W06F0002_GET_DECIMAL_QUANTITY_SUCCESS",
    W06F0002_GET_DETAIL_SUPPLEMENT: "W06F0002_GET_DETAIL_SUPPLEMENT",
    W06F0002_GET_DETAIL_SUPPLEMENT_SUCCESS: "W06F0002_GET_DETAIL_SUPPLEMENT_SUCCESS",
    W06F0002_GET_MASTER_SUPPLEMENT: "W06F0002_GET_MASTER_SUPPLEMENT",
    W06F0002_GET_MASTER_SUPPLEMENT_SUCCESS: "W06F0002_GET_MASTER_SUPPLEMENT_SUCCESS",
    W06F0002_GET_CBO_PACKING_TYPE: "W06F0002_GET_CBO_PACKING_TYPE",
    W06F0002_GET_CBO_PACKING_TYPE_SUCCESS: "W06F0002_GET_CBO_PACKING_TYPE_SUCCESS",
    W06F0002_GET_CBO_UNIT: "W06F0002_GET_CBO_UNIT",
    W06F0002_GET_CONTRACT_NO: "W06F0002_GET_CONTRACT_NO",
    W06F0002_GET_CONTRACT_NO_SUCCESS: "W06F0002_GET_CONTRACT_NO_SUCCESS",
    W06F0002_LOAD_FORM_DATA: "W06F0002_LOAD_FORM_DATA",
    W06F0002_LOAD_FORM_DATA_SUCCESS: "W06F0002_LOAD_FORM_DATA_SUCCESS",
    W06F0002_SAVE_DATA_HISTORY: "W06F0002_SAVE_DATA_HISTORY",
    W06F0002_SAVE_DATA_HISTORY_SUCCESS: "W06F0002_SAVE_DATA_HISTORY_SUCCESS",
    W06F0002_SAVE_ADD_NEW: "W06F0002_SAVE_ADD_NEW",
    W06F0002_SAVE_ADD_NEW_SUCCESS: "W06F0002_SAVE_ADD_NEW_SUCCESS",
    W06F0002_SAVE_EDIT: "W06F0002_SAVE_EDIT",
    W06F0002_SAVE_EDIT_SUCCESS: "W06F0002_SAVE_EDIT_SUCCESS",
    W06F0002_GET_CBO_PAYMENT_METHOD: "W06F0002_GET_CBO_PAYMENT_METHOD",
    W06F0002_GET_CBO_PAYMENT_METHOD_SUCCESS: "W06F0002_GET_CBO_PAYMENT_METHOD_SUCCESS",
    W06F0002_GET_DATA_FORM_VIEW: "W06F0002_GET_DATA_FORM_VIEW",
    W06F0002_GET_DATA_FORM_VIEW_SUCCESS: "W06F0002_GET_DATA_FORM_VIEW_SUCCESS",
    W06F0002_SAVE_CBO_UNIT: "W06F0002_SAVE_CBO_UNIT",
    W06F0002_SAVE_CBO_UNIT_SUCCESS: "W06F0002_SAVE_CBO_UNIT_SUCCESS",
    W06F0002_GET_EXCEEDCONTRACT: "W06F0002_GET_EXCEEDCONTRACT",
    W06F0002_GET_CBO_BANK_ACCOUNT: "W06F0002_GET_CBO_BANK_ACCOUNT",
    W06F0002_GET_CBO_BANK_ACCOUNT_SUCCESS: "W06F0002_GET_CBO_BANK_ACCOUNT_SUCCESS"
};

export function loadCboContractType(cb) {
    return {
        type: types.W06F0002_GET_CBO_CONTRACT_TYPE,
        cb
    }
}

export function loadCboCurrency(params, cb) {
    return {
        type: types.W06F0002_GET_CBO_CURRENCY,
        params,
        cb
    }
}

export function loadCboEmployees(params, cb) {
    return {
        type: types.W06F0002_GET_CBO_EMPLOYEES,
        params,
        cb
    }
}

export function loadCboPackingMethod(params, cb) {
    return {
        type: types.W06F0002_GET_CBO_PACKING_METHOD,
        params,
        cb
    }
}

export function loadBaseCurrency(params, cb) {
    return {
        type: types.W06F0002_GET_BASE_CURRENCY,
        params,
        cb
    }
}

export function loadCboStatus(params, cb) {
    return {
        type: types.W06F0002_GET_CBO_STATUS,
        params,
        cb
    }
}

export function getInventories(params, cb) {
    return {
        type: types.W06F0002_GET_INVENTORIES,
        params,
        cb
    }
}

export function getDeicimalQuantity(params, cb) {
    return {
        type: types.W06F0002_GET_DECIMAL_QUANTITY,
        params,
        cb
    }
}

export function getDetailSupplement(params, cb) {
    return {
        type: types.W06F0002_GET_DETAIL_SUPPLEMENT,
        params,
        cb
    }
}

export function getMasterSupplement(params, cb) {
    return {
        type: types.W06F0002_GET_MASTER_SUPPLEMENT,
        params,
        cb
    }
}

export function loadCboPackingType(params, cb) {
    return {
        type: types.W06F0002_GET_CBO_PACKING_TYPE,
        params,
        cb
    }
}

export function loadCboUnit(params, cb) {
    return async () => {
        await Api.put('/w05f0000/get-combo-unit', params)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    cb && cb(result, null);
                } else if (result.data) {
                    cb && cb(null, result.data);
                    return result.data;
                } else {
                    cb && cb(null, null);
                }
            });
    }
}

export function getContractNo(params, cb) {
    return {
        type: types.W06F0002_GET_CONTRACT_NO,
        params,
        cb
    }
}

export function loadForm(params, cb) {
    return {
        type: types.W06F0002_LOAD_FORM_DATA,
        params,
        cb
    }
}

export function saveHistoryData(params, cb) {
    return {
        type: types.W06F0002_SAVE_DATA_HISTORY,
        params,
        cb
    }
}

export function saveEdit(params, cb) {
    return {
        type: types.W06F0002_SAVE_EDIT,
        params,
        cb
    }
}

export function saveAddNew(params, cb) {
    return {
        type: types.W06F0002_SAVE_ADD_NEW,
        params,
        cb
    }
}

export function loadCboPaymentMethod(params, cb) {
    return {
        type: types.W06F0002_GET_CBO_PAYMENT_METHOD,
        params,
        cb
    }
}

export function loadFormView(params, cb) {
    return {
        type: types.W06F0002_GET_DATA_FORM_VIEW,
        params,
        cb
    }
}

export function onSaveDataUnit(dataCboUnit) {
    return {
        type: types.W06F0002_SAVE_CBO_UNIT,
        dataCboUnit
    }
}

export function getExceedContract(params, cb) {
    return {
        type: types.W06F0002_GET_EXCEEDCONTRACT,
        params,
        cb
    }
}

export function getCboBankAccount(params, cb) {
    return {
        type: types.W06F0002_GET_CBO_BANK_ACCOUNT,
        params,
        cb
    }
}