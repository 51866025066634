import Api from "../../services/api";

export const types = {
    GET_PERMISSION: 'GET_PERMISSION',
    GET_PERMISSION_SUCCESS: 'GET_PERMISSION_SUCCESS',
    GET_CBO_ORGCHART: 'GET_CBO_ORGCHART',
    GET_CBO_ORGCHART_SUCCESS: 'GET_CBO_ORGCHART_SUCCESS',
    //filter
    GET_CBO_DUTY: 'GET_CBO_DUTY',
    GET_CBO_DUTY_SUCCESS: 'GET_CBO_DUTY_SUCCESS',
    GET_CBO_EMPLOYEE: 'GET_CBO_EMPLOYEE',
    GET_CBO_EMPLOYEE_SUCCESS: 'GET_CBO_EMPLOYEE_SUCCESS',
    GET_CBO_PROJECT: 'GET_CBO_PROJECT',
    GET_CBO_PROJECT_SUCCESS: 'GET_CBO_PROJECT_SUCCESS',
    GET_GRID_GENERAL_INFORMATION: "GET_GRID_GENERAL_INFORMATION",
    GENERAL_GET_CBO_DEPARTMENTS: 'GENERAL_GET_CBO_DEPARTMENTS',
    GENERAL_GET_CBO_DEPARTMENTS_SUCCESS: 'GENERAL_GET_CBO_DEPARTMENTS_SUCCESS',
    GENERAL_GET_CBO_TEAM: 'GENERAL_GET_CBO_TEAM',
    GENERAL_GET_CBO_TEAM_SUCCESS: 'GENERAL_GET_CBO_TEAM_SUCCESS',
    GENERAL_GET_CBO_WORKS: 'GENERAL_GET_CBO_WORKS',
    GENERAL_GET_CBO_WORKS_SUCCESS: 'GENERAL_GET_CBO_WORKS_SUCCESS',
    GET_CBO_APP_STATUS: "GET_CBO_APP_STATUS",
    GET_CBO_APP_STATUS_SUCCESS: "GET_CBO_APP_STATUS_SUCCESS",
    GENERAL_GET_ATTACHMENTS_BY_TRANSID: 'GENERAL_GET_ATTACHMENTS_BY_TRANSID',
    GENERAL_EXPORT_EXCEL_TEMPLATE: 'GENERAL_EXPORT_EXCEL_TEMPLATE',
    GENERAL_EXPORT_EXCEL_TEMPLATE_SUCCESS: 'GENERAL_EXPORT_EXCEL_TEMPLATE_SUCCESS',
    GENERAL_LOAD_HISTORY: "GENERAL_LOAD_HISTORY",
    GENERAL_LOAD_HISTORY_SUCCESS: "GENERAL_LOAD_HISTORY_SUCCESS",
    GENERAL_LOAD_CBO_REPORT: "GENERAL_LOAD_CBO_REPORT",
    GENERAL_LOAD_CBO_REPORT_SUCCESS: "GENERAL_LOAD_CBO_REPORT_SUCCESS",
    GENERAL_EXPORT_REPORT: "GENERAL_EXPORT_REPORT",
    GENERAL_EXPORT_REPORT_SUCCESS: "GENERAL_EXPORT_REPORT_SUCCESS",
    GENERAL_GET_INVENTORIES: "GENERAL_GET_INVENTORIES",
    GENERAL_GET_COMPANIES: "GENERAL_GET_COMPANIES",
    GENERAL_GET_COMPANIES_SUCCESS: "GENERAL_GET_COMPANIES_SUCCESS",
    GENERAL_SAVE_EXPORT_BTN_STATE: "GENERAL_SAVE_EXPORT_BTN_STATE",
    GENERAL_SAVE_EXPORT_BTN_STATE_SUCCESS: "GENERAL_SAVE_EXPORT_BTN_STATE_SUCCESS",
    GENERAL_PASS_PARAM_EXPORT: "GENERAL_PASS_PARAM_EXPORT",
    GENERAL_EXPORT_FLEX_REPORT: "GENERAL_EXPORT_FLEX_REPORT",
    GENERAL_GET_OBJECT: "GENERAL_GET_OBJECT",
    GENERAL_GET_OBJECT_SUCCESS: "GENERAL_GET_OBJECT_SUCCESS"
};

export function resetPermission() {
    return async (dispatch) => {
        dispatch({ type: types.GET_PERMISSION_SUCCESS, data: 1 });
    };
}
/**
 * Get permission
 * @param params: @formIDs (array or string)
 * @param cb
 * @returns {Promise<void>}
 */
export function getPermission(formIDs, cb, flag) {
    let listScreenIDs = Array.isArray(formIDs) ? formIDs : [formIDs];
    const params = { FormID: JSON.stringify(listScreenIDs) };

    return async (dispatch) => {
        !flag && dispatch({ type: types.GET_PERMISSION_SUCCESS, data: null });
        await Api.put('/permission/get-permissions', params)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    !flag && dispatch({ type: types.GET_PERMISSION_SUCCESS, data: 0 });
                    cb && cb(0);
                } else if (result.data && result.data.length > 0) {
                    if (formIDs && Array.isArray(formIDs)) {
                        !flag && dispatch({ type: types.GET_PERMISSION_SUCCESS, data: result.data[0].Permission });
                        cb && cb(result.data);
                    }
                    else if (result.data[0] && result.data[0].Permission) {
                        !flag && dispatch({ type: types.GET_PERMISSION_SUCCESS, data: result.data[0].Permission });
                        cb && cb(result.data[0].Permission);
                    }
                    else {
                        !flag && dispatch({ type: types.GET_PERMISSION_SUCCESS, data: 0 });
                        cb && cb(0);
                    }
                } else {
                    !flag && dispatch({ type: types.GET_PERMISSION_SUCCESS, data: 0 });
                    cb && cb(0);
                }
            });
    }
}

//get cbo orgcharts..
export function getOrgCharts(params, cb) {
    return {
        type: types.GET_CBO_ORGCHART,
        params,
        cb
    }
}

//get cbo duty..
export function getCboDuty(params, cb) {
    return {
        type: types.GET_CBO_DUTY,
        params,
        cb
    }
}

//get cbo employees..
export function getCboEmployees(params, cb) {
    return {
        type: types.GET_CBO_EMPLOYEE,
        params,
        cb
    }
}

//get cbo projects..
export function getCboProjects(params, cb) {
    return {
        type: types.GET_CBO_PROJECT,
        params,
        cb
    }
}

//get cbo departments..
export function getCboDepartments(params, cb) {
    return {
        type: types.GENERAL_GET_CBO_DEPARTMENTS,
        params,
        cb
    }
}

//get cbo departments..
export function getCboTeam(params, cb) {
    return {
        type: types.GENERAL_GET_CBO_TEAM,
        params,
        cb
    }
}

//get cbo departments..
export function getCboWorks(params, cb) {
    return {
        type: types.GENERAL_GET_CBO_WORKS,
        params,
        cb
    }
}

//get attachments by TransID (Key01ID)..
export function getAttachmentsByTransID(params, cb) {
    return {
        type: types.GENERAL_GET_ATTACHMENTS_BY_TRANSID,
        params,
        cb
    }
}

export function getCboAppStatus(params, cb) {
    return {
        type: types.GET_CBO_APP_STATUS,
        params,
        cb
    };
}


//export excel template....
export function exportExcelTemplate(params, cb) {
    return {
        type: types.GENERAL_EXPORT_EXCEL_TEMPLATE,
        params,
        cb
    }
}

//Get Grid General Information
export function getGridGeneralInformation(params, cb) {
    return {
        type: types.GET_GRID_GENERAL_INFORMATION,
        params,
        cb
    }
}


//Load History
export function loadHistory(params, cb) {
    return {
        type: types.GENERAL_LOAD_HISTORY,
        params,
        cb
    }
}

//Load Combo Report
export function loadCboReport(params, cb) {
    return {
        type: types.GENERAL_LOAD_CBO_REPORT,
        params,
        cb
    }
}

//Export Report
export function exportReport(params, cb) {
    return {
        type: types.GENERAL_EXPORT_REPORT,
        params,
        cb
    }
}

//Load History
export function getReports(params, cb) {
    return {
        type: types.GENERAL_LOAD_CBO_REPORT,
        params,
        cb
    }
}

//Save History
export function saveHistory(params, cb) {
    return async () => {
        return await Api.put('/history/add', params)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    cb && cb(result, null);
                    return null;
                } else if (result.data) {
                    cb && cb(null, result.data);
                    return result.data;
                } else {
                    cb && cb(result, null);
                    return null;
                }
            });
    }
}

//Load Select Inventory Modal
export function getInventories(params, cb) {
    return {
        type: types.GENERAL_GET_INVENTORIES,
        params,
        cb
    }
}

//Load Select Company Modal
export function getCompanies(params, cb) {
    return {
        type: types.GENERAL_GET_COMPANIES,
        params,
        cb
    }
}

//Save export button state
export function onSaveExportBtnState(btnExportState) {
    return {
        type: types.GENERAL_SAVE_EXPORT_BTN_STATE,
        btnExportState
    }
}

//Export flex report
export function exportFlexReport(params, cb) {
    return {
        type: types.GENERAL_EXPORT_FLEX_REPORT,
        params,
        cb
    }
}

//Check param export
export function passParametersExport(params, cb) {
    return {
        type: types.GENERAL_PASS_PARAM_EXPORT,
        params,
        cb
    }
}

//Load Select Object Modal
export function getObject(params, cb) {
    return {
        type: types.GENERAL_GET_OBJECT,
        params,
        cb
    }
}


